import { Row, Col, Card } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";

import img1 from "../../assets/images/image3.png";
import img2 from "../../assets/images/image1.png";
import img3 from "../../assets/images/image2.png";



import ButtonPrimary from "../../components/buttons/buttons";
import "./home.css"
import { To, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BaseAPI } from "../../providers/base-api";
import { CONTENT_TYPES } from "../../providers/models/home-cards";



const HomePage = () => {
  const navigate = useNavigate();
  const handleClick = function (linkTo: To) {
    navigate(linkTo)
  }
  const [content, setContent] = useState([])

  const getContent = async () => {

    try {
      await BaseAPI.getContent(CONTENT_TYPES.HOME_CARDS, (result: any) => {

        if (result.status === 200) {

          setContent(result.data.data.items);


        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getContent();
  }, []);


  return (
    <>


      <div >
        <div className="bg-clr">
          <div className="banner-div">
            <Row>
              <Col xs={6} md={6} className="title-align">

                <div >
                  <h3 className="tit-hd">Matching Community Priorities with Federal Infrastructure Funding</h3>
                </div>
                <div>
                  <p className="tit-p">Better understand if your infrastructure project might be eligible and ready for federal funding.</p>
                </div>
                <div className="test">
                  <ButtonPrimary className="btn-gt " onClick={() => handleClick("/projects/new")}> GET STARTED <ArrowRight className="arrow arrow-align" size={28} />  </ButtonPrimary>
                </div>
              </Col>
              <Col xs={6} md={6} >
                <Row className="img-ht">
                  <Col><img className="banner-one img-design" src={img1} /></Col>
                  <Col><img className="banner-two img-design" src={img2} /></Col>
                  <Col><img className="banner-three img-design" src={img3} /></Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="ht-len">
        <div className="d-flex">
          <div className="container ">
            <Row className="card-len" >
              {content && content.map((data, index) => {
                  return (
                   
                    <Col className="card-col" key={`cardc-${index}`}>
                      <Card className="col-border" key={`card-${index}`}>
                        <Card.Body className="card-shadow">
                          <h3 className="card-h3">{data.title}</h3>
                          <p className="card-p">{data.display_title} </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
              })}
            </Row>
          </div>
        </div>
      </div>
    </>

  )
}
export default HomePage