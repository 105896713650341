import Modal from "react-bootstrap/Modal";
import "./model.css";

export default function ModelWindow(props: any) {
  return (
    <Modal
      show={props.show}
      {...props}
      aria-labelledby="example-custom-modal-styling-title"
      centered
      dialogClassName={props?.dialogClassName}
      contentClassName={props?.contentClassName}
    >
       <Modal.Header closeButton>
      </Modal.Header>

      <Modal.Body className="modalBody">{props.body}</Modal.Body>
    </Modal>
  );
}
