import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import ButtonPrimary from "../../components/buttons/buttons";
import ReCAPTCHA from "react-google-recaptcha";
import TitleCard from "../../components/title-card/titlecard";
import "./contact-us.css";
import { ContactUsValitation as ContatUsForm } from "../../models/project";
import { useState } from "react";
import isEmail from 'validator/es/lib/isEmail';
import { BaseAPI } from "../../providers/base-api";

const ContactUsPage = ({onHide}) => {
  const [contactData, setContactData] = useState<ContatUsForm>({
    email: "",
    subject: "",
    message: "",
    captchaToken: "",
  });

  const errorMessages = {
    required: 'This field is required',
    emailInvalid: 'Please enter a valid email',
    submissionError: 'There was an error submitting your details',
    validCaptchaRequired: 'Valid captcha is required to submit this form'
  }
  const [errors, setErrors] = useState({ email: '', form: '',captcha: '' })
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setContactData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const [validated, setValidated] = useState(false);
  const onSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    let isFormValid = true;
    if (!contactData?.email) {
      setErrors((prev) => {return { ...prev, email: errorMessages.required }})
      isFormValid = false;
    } else if (!isEmail(contactData?.email)) {
      setErrors((prev) => {return { ...prev, email: errorMessages.emailInvalid }})
      isFormValid = false;
    }
    if(!contactData.captchaToken) {
      setErrors((prev) => {return { ...prev, form: errorMessages.validCaptchaRequired }})
      isFormValid = false;
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false || !isFormValid) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
      return
    }
    event.preventDefault();
    event.stopPropagation();

    try {
      await BaseAPI.submitContactForm(contactData, (result: any) => {
        if (result.status === 201) {
          setFormSubmitted(true)
          setTimeout(() => {
            onHide()
          }, 1500);
          return result.response?.data?.emailValidation;
        } else {
          // setErrors(result?.response?.data?.errors?.emailValidation)
          setErrors((prev) => {return { ...prev, form: errorMessages.submissionError }})
        }
      });
    } catch (error) {
      setErrors((prev) => {return { ...prev, form: errorMessages.submissionError }})
      // setErrors(error?.response?.data?.errors?.emailValidation)
    }
  };
  const onChange = async (value: any) => {
    setContactData((prevalue) => {
      return {
        ...prevalue,
        captchaToken: value,
      };
    });
    
    setErrors({ email: '', form: '',captcha: '' })
  };

  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_KEY;

  return (
    //   <Alert variant="danger">
    //   <Alert.Heading>Mailing Failed !</Alert.Heading>
    //   <p>
    //     Verification Failed 
    //   </p>
    // </Alert>
    <div className="contact-page layout-container-content d-flex flex-fill flex-column">
      <div className="d-flex flex-fill contact page">
        <Container className="container contact">
          <Row className="contact">
            <Col lg={5}>
              <div className="contact-text">
                <TitleCard
                  title="Contact Us"
                  description="The IFN is still a beta application and it’s designed to be a helpful resource so please share your feedback with us. How else could this tool help you advance critical projects in your community?"
                  descriptionClass="contact-subtitle"
                />

                <div style={{marginTop: "220px"}}>
                {formSubmitted &&
                  <Alert key="success" variant="success">
                    Your submission was sent successfully.
                  </Alert>
                }
                {errors.form &&
                  <Alert key="danger" variant="danger">
                  {errors.form}
                </Alert>
                }
                </div>
              </div>
            </Col>
            <Col lg={7} className="contact-col-7">
              <Form
                className="contactForm"
                noValidate
                validated={validated}
                onSubmit={onSubmit}
              >
                <Form.Group as={Col} md="12">
                  <Form.Control
                    name="email"
                    className="contact-email"
                    type="email"
                    value={contactData.email}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    placeholder="Your email *"
                    required
                  />
                  {errors.email &&
                    <Form.Control.Feedback type="invalid" className="contatus-error-message-dis">
                      {errors.email}
                    </Form.Control.Feedback>
                  }
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Control
                    className="contact-sub"
                    type="text"
                    name="subject"
                    value={contactData.subject}
                    onChange={handleChange}
                    placeholder="Subject"
                  />
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Control as="textarea"
                    className="contact-message"
                    type="textarea"
                    name="message"
                    value={contactData.message}
                    onChange={handleChange}
                    placeholder="Your message *"
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <ReCAPTCHA
                    className="con-recaptcha"
                    size="normal"
                    name="reCaptcha"
                    onChange={onChange}
                    sitekey={captchaSiteKey}
                  />
                </Form.Group>
                
                <ButtonPrimary className="contact-button" type="submit">
                  SEND &nbsp;
                  <ArrowRight className="arrow"  size={28} />
                </ButtonPrimary>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactUsPage;
