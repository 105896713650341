import { useMemo } from "react";
import { useEffect, useState } from "react";
import { Collapse, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProjectContextType, useProject } from "../../pages/project/project";
import './project-sidebar.css';

interface MenuStructure {
    name?: string;
    id?: number;
    items?: MenuStructure[];
    type: 'group' | 'item';
    path?: string;
    hide?: boolean;
    key?: string;
    showSequence?: boolean;
    expand?: boolean;
    qCount?: number;
    aCount?: number;
}

export interface MenuState {
    [key: string]: boolean;
}
export default function ProjectSidebar() {

    const projectContext: ProjectContextType = useProject()
    // const projectId = props?.projectId;
    const navigate = useNavigate();
    const defaultMenu: MenuStructure[] = useMemo(() => [
        {
            "name": "INITIAL QUESTIONS & ELIGIBILITY",
            "items": [
                { "name": "Project Eligibility Questionnaire", "path": "/projects/:id", "type": "item", "key": "1.1.1" },
                { "name": "Funding Eligibility", "path": "/projects/:id/programs", "type": "item", "key": "1.1.2" },

            ],
            "type": "group",
            "key": "1.1"
        },
        {
            "name": "PROJECT READINESS ASSESSMENT",

            "items": [],
            "type": "group",
            "path": "/projects/:id/pca/intro",
            "showSequence": true,
            "key": "1.2"

        }
    ], []);

    const [menuState, setMenuState] = useState<MenuState>({})
    const [currentMenu, setCurrentMenu] = useState<string>()

    const [menuList, setMenuList] = useState<MenuStructure[]>(
        defaultMenu
    )

    useEffect(() => {
        if (!(projectContext.currentCriteriaId && projectContext.currentProjectComponentId)) {
            return;
        }
        const currentSectionKey = `1.2.pc${projectContext.currentProjectComponentId}`
        const currentMenuKey = `${currentSectionKey}.c${projectContext.currentCriteriaId}`
        setCurrentMenu(currentMenuKey)
        // if(menuState.hasOwnProperty(currentSectionKey) && !menuState[currentSectionKey]) {
        //     const newState = !(menuState[currentSectionKey]);
        //     console.log(currentSectionKey, newState)
        setMenuState({ [currentSectionKey]: true })
        // }

    }, [projectContext.currentCriteriaId, projectContext.currentProjectComponentId, projectContext.project.id])

    useEffect(() => {
        if (!projectContext?.projectPCA) {
            return;
        }
        try {
            // console.log('reloading sidebar.useEffect', projectContext.project)
            const currentProjectId = projectContext.project.id;

            const result = { data: projectContext.projectPCA }
            const previousData = [...defaultMenu]
            const menuItems: MenuStructure[] = result.data.pcataxonomy?.map((taxonomyItem) => {
                const menuItemKey = `1.2.pc${taxonomyItem.id}`
                const menuItem: MenuStructure = {
                    name: taxonomyItem.name,
                    type: 'item',
                    key: menuItemKey
                }
                if (taxonomyItem.children && taxonomyItem.children?.length > 0) {
                    const criteriaMenuItems: MenuStructure[] = taxonomyItem.children.map((criteriaItem) => {
                        const subMenuItemKey = `${menuItemKey}.c${criteriaItem.id}`
                        // const path = routePath.indexOf('projects/:id') > 0 ? routePath.replaceAll('projects/:id', `projects/${projectId}`) : routePath;

                        const criteriaMenuItem: MenuStructure = {
                            name: criteriaItem.name,
                            type: 'item',
                            key: subMenuItemKey,
                            path: `/projects/${currentProjectId}/pca/questions?pcid=${taxonomyItem.id}&cid=${criteriaItem.id}&from=m`,
                            qCount: criteriaItem.questionCount,
                            aCount: criteriaItem.answerCount
                        }
                        return criteriaMenuItem;
                    })
                    menuItem.items = criteriaMenuItems;
                    menuItem.type = 'group';
                    // if(!firstMenu) {
                    //     firstMenu = true;
                    //     setMenuState({menuItemKey: true})
                    // }

                    // menuItem.expand = true;
                }
                return menuItem;
            })
            previousData[1].items = [...menuItems];
            setMenuList(previousData)
        } catch (error) {
            console.log(error);
        }
    }, [defaultMenu, projectContext.projectPCA, menuState, projectContext.project]);

    const handleNavigation = (event: any, pCompMenuItem: MenuStructure, criteriaMenuItem: MenuStructure) => {
        const routePath = criteriaMenuItem.path;
        const currentProjectId = projectContext.project.id;

        const path = routePath.indexOf('projects/:id') > 0 ? routePath.replaceAll('projects/:id', `projects/${currentProjectId}`) : routePath;

        navigate(path)
        setCurrentMenu(criteriaMenuItem.key)
    }
    const handleExpandToggle = (menuItem: MenuStructure) => {
        const menuItemKey = menuItem.key
        if (!menuItemKey) {
            return;
        }

        const newState = !(menuState[menuItemKey]);
        setMenuState({ [menuItemKey]: newState })
        setCurrentMenu(menuItem.key)
    }
    const newProject = projectContext.project.id === 'new';
    return (
        <aside className="col-3 layout-sidebar">
            {menuList &&
                menuList
                    .filter(menuItem => menuItem && menuItem.items && menuItem.items.length > 0)
                    .map((menuItem, menuIndex) => {
                        const menuKey = menuIndex;
                        return (
                            <Nav className="ms-auto saidebar-group-text justify-content-left nav-bar p-0" as="ul" key={menuKey}>
                                <li>
                                    <Navbar.Text key={menuKey}>
                                        <div className="menu-group-container">
                                            {menuItem.name}
                                        </div>
                                    </Navbar.Text>
                                </li>
                                {menuIndex ? <Nav.Item as="li" key={`li-0`} className={`navigable-item p-0  width`} >
                                    <Nav.Link className="nav-side-link" onClick={() => navigate(`/projects/${projectContext.project.id}/pca/intro`)} >
                                        <span className="seq-number side-menu-intro"></span>Introduction
                                    </Nav.Link>
                                </Nav.Item> : null}
                                {menuItem.items?.map((subMenuItem: MenuStructure, index: number) => {
                                    let isDisabled = false;
                                    const itemKey = `${menuKey}${index}`;
                                    if (subMenuItem.key && ['1.1.2'].includes(subMenuItem.key) && newProject) {
                                        isDisabled = true;
                                    }
                                    const isLeafItem = subMenuItem.type === 'item';
                                    const leafItemClass = isLeafItem ? 'navigable-item' : 'navigable-item';
                                    return (
                                        <Nav.Item as="li" key={`li-${itemKey}`} className={`${leafItemClass} p-0  width`} >
                                            {isLeafItem &&
                                                <Nav.Link className="nav-side-link" disabled={isDisabled} key={`a-${itemKey}`}
                                                    onClick={(event) => handleNavigation(event, menuItem, subMenuItem)}
                                                    active={subMenuItem.key === currentMenu}
                                                >
                                                    {menuItem.showSequence &&
                                                        <span key={index} className="seq-number">{index + 1}</span>
                                                    }
                                                    {subMenuItem.name}
                                                </Nav.Link>
                                            }
                                            {!isLeafItem &&
                                                <Nav.Link className="nav-side-link" disabled={isDisabled} key={`a-${itemKey}`}
                                                    onClick={subMenuItem.type === 'group' ? () => handleExpandToggle(subMenuItem) : undefined} >
                                                    {menuItem.showSequence &&
                                                        <span key={index} className="seq-number">{index + 1}</span>
                                                    }
                                                    {subMenuItem.name}
                                                </Nav.Link>
                                            }                            <Collapse in={(subMenuItem.key && menuState[subMenuItem.key]) || currentMenu === subMenuItem.key} key={`li-${itemKey}`}>
                                                <div>
                                                    {
                                                        subMenuItem.items?.map((criteriaMenuItem: MenuStructure, criteriaIndex: number) => {
                                                            const criteriaSequence = `${index + 1}.${criteriaIndex + 1}`
                                                            return (

                                                                <Nav.Item key={`ni-${itemKey}-${criteriaIndex}`}>
                                                                    <Nav.Link className="navigable-item nav-side-link nav-indent-2" disabled={isDisabled}
                                                                        key={`nl-${itemKey}-${criteriaIndex}`}
                                                                        onClick={(event) => handleNavigation(event, subMenuItem, criteriaMenuItem)}
                                                                        active={criteriaMenuItem.key === currentMenu}
                                                                    >
                                                                        {menuItem.showSequence &&
                                                                            <span key={criteriaIndex} className="seq-number">{criteriaSequence}</span>
                                                                        }
                                                                        {criteriaMenuItem.name}
                                                                        <span className="answer-ratio">{criteriaMenuItem.aCount}/{criteriaMenuItem.qCount}</span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Collapse>
                                        </Nav.Item>
                                    )
                                }
                                )
                                }
                            </Nav>)
                    })}
        </aside>
    )
}
