import { Container, Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModelWindow from "../Modal/ModelWindow";
import { useEffect, useState } from "react";
import ContactUsPage from "../../pages/contact-us/contact-us";
import "./header.css";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import SignupOrLogin from "../Modal/SignupOrLogin";
function Header() {
  const [modalShow, setModalShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showState, setShowState] = useState(false);
  const [modalType, setModalType] = useState("");
  const navigate = useNavigate();

  const { user, logout, targetPath,setTargetPath  } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    setIsLoggedIn(false);
    setModalType(undefined)
    logout(targetPath || "/");
  };

  useEffect(() => {
    if (user) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      setIsLoggedIn(true);
      if (modalType === "login" && showState) {
        setShowState(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [user, modalType, showState]);

  useEffect(() => {
    // console.log('header', {hash: location.hash,modalType, state: location.state})
    if(location.hash && location.hash === '#login' && modalType !== 'login') {
      if(location.state && location.state['from'] ) {
        setTargetPath(location.state['from'])
      }
      setModalType('login')
      navigate('/',{replace: true})

    }

  },[location,modalType,setTargetPath, navigate])

  return (
    <div className="App">
      <Navbar bg="light" expand="lg" sticky="top" className="hd-navs-bar-wd-align">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/IFN Logo-01-01.png"
              // src="/logo-retina.png"
              className="d-inline-block align-top"
              alt="HR&amp;A"
              width="89"
              height="41"
            />
          </Navbar.Brand>
          <div className="beta">BETA</div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="ml-auto justify-content-end"
          >
            <Nav className="ml-auto justify-content-end">
              <Nav.Link as={Link} to="/programs">
                PROGRAMS
              </Nav.Link>

              <Nav.Link as={Link} to="/projects">
                MY PROJECTS
              </Nav.Link>

              {!isLoggedIn ? (
                <Nav.Link
                  onClick={(event) => setModalType("signup")}
                >
                  SIGN UP
                </Nav.Link>
              ) : null}

              <Nav.Link
                className="app-login header-navbar-loginalign"
                onClick={
                  isLoggedIn
                    ? () => handleLogout()
                    : (event) => setModalType("login")
                }
              >
                {isLoggedIn ? "LOGOUT" : "LOG IN"}
              </Nav.Link>
              <Nav.Link onClick={() => setModalShow(true)}>CONTACT US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ModelWindow
        size="xl"
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={<ContactUsPage onHide={() => setModalShow(false)} />}
      />
      <SignupOrLogin modalType={modalType} setModalType={setModalType}></SignupOrLogin>
    </div>
  );
}
export default Header;
