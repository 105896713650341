import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/header";
import { GlobalRoutesMap } from "./routes";
import { AuthProvider } from "./hooks/useAuth";

import { BrowserView, MobileView } from "react-device-detect";
import { Col } from "react-bootstrap";
import { useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import Footer from "./components/footer/footer";

function App() {
  const desktopView = useMediaQuery("(min-width:1024px)");

  return (
    <div className="App">
      <AuthProvider>
        <Header />
        {!desktopView ? (
          <>
          <MobileView className="app-mob-view-dis d-flex align-items-center justify-content-center">
            <Col>
              <h3 className="app-head-design">Desktop view only</h3>
              <p className="app-para-design">
                To access the site please view this link a desktop browser
              </p>
            </Col>
          </MobileView>
            <BrowserView className="app-mob-view-dis d-flex align-items-center justify-content-center text-center">
            <Col>
              <h3>Desktop view only</h3>
              <p>
                To access the site please view this link a desktop browser
              </p>
            </Col>
          </BrowserView>
          </>
        ) :  (
          <BrowserView>
            <GlobalRoutesMap />
          </BrowserView>
        ) }
         <Footer />
      </AuthProvider>
      
    </div>
  );
}

export default App;
