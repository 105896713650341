import { useCallback, useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ButtonPrimary from "../../components/buttons/buttons";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import ScoreCard from "../score-card/score-card";
import DoughnutChartWithCenterText from "./doughnut-center";
import { ProjectContextType, ProjectContext } from "../project/project";
import "./scorecard-chart.css";

interface FederalPriorityScore {
    priorityAreaName: string;
    score: number;
    maxScore?: number;
    scorePct?: number;
}

const ScorePrioritySummary = () => {
    const [showTable, setShowTable] = useState(false);
    const params = useParams();
    const projectId = params && params.id ? +params.id : undefined;
    const projectContext: ProjectContextType = useContext(ProjectContext);
    const [priorityScoreData, setPriorityScoreData] = useState<FederalPriorityScore[]>([]);

    const height = 140;
    const options = {
        height: height,
        width: height,
        cutout: 85
    }

    const getPriorityScores = useCallback(async (defaultPriorityScoreData: FederalPriorityScore[]) => {
        await BaseAPI.getPriorityScores(projectId, (result: any) => {
            if (result.status === 200) {
                const priorityScoreResult: FederalPriorityScore[] = result.data;
                const priorityScoreSumary = priorityScoreResult.map(priorityScoreRow => {
                    priorityScoreRow.score = priorityScoreRow.score || 0;
                    priorityScoreRow.score = priorityScoreRow.maxScore === 0 ? 0 : Math.round((priorityScoreRow.score / priorityScoreRow.maxScore) * 100);
                    priorityScoreRow.scorePct = priorityScoreRow.scorePct || 0;
                    return priorityScoreRow;
                })
                if(!priorityScoreSumary || priorityScoreSumary.length === 0) {
                    setPriorityScoreData(defaultPriorityScoreData)
                } else {
                    setPriorityScoreData(priorityScoreSumary)
                }
            } else {
                setPriorityScoreData([])
                console.log(result.message);
            }

        });
    }, [projectId])

    useEffect(() => {
        const defaultPriorityScoreData: FederalPriorityScore[] = [
            { priorityAreaName: 'Project Design', score: 0, scorePct: 0 },
            { priorityAreaName: 'Sustainability', score: 0, scorePct: 0 },
            { priorityAreaName: 'Planning', score: 0, scorePct: 0 },
            { priorityAreaName: 'Governance', score: 0, scorePct: 0 },
            { priorityAreaName: 'Implementation', score: 0, scorePct: 0 },
        ];
        getPriorityScores(defaultPriorityScoreData);


        // Get data from API and set here.
    }, [getPriorityScores, projectId])

    return (
        <div className="scorecard-container layout-container-content d-flex flex-fill flex-column project-container" id="scorecard-container">
            <div className="d-flex flex-fill ">
                <main role="main" className="layout-main flex-fill">
                    <Container>
                        <div className="main">
                            <TitleCard
                                title="Federal Priority Overview"
                                description2="The IFN assesses project readiness based on HR&A’s interpretation of successful applications for federal funding, The tool  offers insights into your project’s readiness to potentially access this federal funding. While this tool offers insights, the Project Readiness Breakdown below is meant to be informational and does not guarantee nor offer access to federal funding. The  IFN tool assesses readiness and is not an application for federal funding."

                            />
                            <br />
                            <div className="arrange-horizontally sc-card-ht">
                                {priorityScoreData.map((dataItem, chartIndex: number) => {
                                    return (
                                        <div className="single-doughnut" key={chartIndex}>
                                            <DoughnutChartWithCenterText options={{ ...options, metric: dataItem.scorePct, label: dataItem.priorityAreaName }} />
                                        </div>
                                    )
                                })}
                            </div>
                            {projectContext?.hasUnansweredQuestions ? <span className='score-card-errormsg'><p>* Note that one or more PRA questions are unanswered. Current assessment is based on the questions attempted so far and is not representative entire PRA.</p></span> : null}
                            <div>
                                <div>
                                    <Row className="sc--hide-btn">
                                        <ButtonPrimary
                                            className="project-listing-btn-align btn-align-width"
                                            onClick={() => setShowTable(!showTable)}
                                        >
                                            {`${showTable ? 'HIDE' : 'SHOW'}`} READINESS BREAKDOWN  {`${showTable ? '-' : '+'}`}

                                        </ButtonPrimary>
                                    </Row>
                                </div>

                            </div>


                        </div>

                    </Container>

                </main>
            </div>
            <Container>
                {showTable && <>
                    <ScoreCard />
                </>
                }

            </Container>
        </div>
    )
}

export default ScorePrioritySummary