export interface SelectOption {
  text: string;
  value: any;
}
export enum CODETABLE_TYPES {
  PROJECT_LOCATION = "project_location",
  IMPLEMENTING_ENTITY = "implementing_entity",
  PROJECT_TYPES = "type_of_project",
  PROJECT_CATEGORY = "project_category",
  PROJECT_COST = "approximate_project_cost",
  PROJECT_ACTIVITY = "eligible_activity",
  PROJECT_SITE = "type_of_site",
  PROJECT_STAGE = "preparation_stage",
  KEYWORD = "keywords",
}
