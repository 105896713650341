import { useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import ButtonPrimary from "../../components/buttons/buttons";
import SelectInput from "../../components/form-inputs/select-inputs";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import { useNavigate } from "react-router-dom";
import { CODETABLE_TYPES } from "../../providers/models/codetable";
import { ProjectContext, ProjectContextType, useProject } from "./project";
import { Typeahead } from "react-bootstrap-typeahead";
import img4 from "../../assets/images/info-icon-blue.png";
// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./newprojects.css";
import { sortDataProgram } from "../../utils/common-utils";

export interface CodeTableResult {
  project_location: any;
  implementing_entity: any;
  type_of_project: any;
  project_category: any;
  approximate_project_cost: any;
  project_activity: any;
  preparation_stage: any;
  keywords: any;
}

export default function CreateProject() {
  const defaultCodeTables: { [key: string]: any[] } = useMemo(() => ({}), []);
  const [codeTableData, setcodeTableData] = useState(defaultCodeTables);
  const [validated, setValidated] = useState(false);
  const { project } = useProject();
  const [projectCategoryList, setProjectCategoryList] = useState([]);
  const [multiSelectKeyword, setMultiSelectKeyword] = useState([]);
  const projectContext: ProjectContextType = useContext(ProjectContext);

  const onSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);

      return;
    }
    setValidated(true);
    try {
      await projectContext.saveProject((savedProjectId) => {
        navigate(`/projects/${savedProjectId}/programs`);
      });
    } catch (error) {
    }
    setValidated(true);
  };

  const handleTypeOfProjectChange = async (value) => {
    // console.log('typeOfProjectId', {value, type: (typeof value)})
    projectContext.handleProjectChange("typeOfProjectId", value);
    projectContext.handleProjectChange("projectCategoryId", '');
  };

  const handleKeywordMultiselect = async (value) => {
    let data = value.map(item => item.value)?.join(',');
    projectContext.handleProjectChange("keywordIds", data);
    setMultiSelectKeyword(value)
  };


  useEffect(() => {
    BaseAPI.getCodeTable([
      CODETABLE_TYPES.KEYWORD,
      CODETABLE_TYPES.PROJECT_LOCATION,
      CODETABLE_TYPES.IMPLEMENTING_ENTITY,
      CODETABLE_TYPES.PROJECT_TYPES,
      CODETABLE_TYPES.PROJECT_CATEGORY,
      CODETABLE_TYPES.PROJECT_COST,
      CODETABLE_TYPES.PROJECT_ACTIVITY,
      CODETABLE_TYPES.PROJECT_STAGE,
    ]).then((result: any) => {
      const keyword = sortDataProgram(result[CODETABLE_TYPES.PROJECT_LOCATION], 'text', 'asc');
      result = { ...result , result,[CODETABLE_TYPES.PROJECT_LOCATION] :keyword  }
      const location = sortDataProgram(result[CODETABLE_TYPES.KEYWORD], 'text', 'asc');
        result = { ...result , result,[CODETABLE_TYPES.KEYWORD] :location  }
      setcodeTableData(result);

    });
  }, []);

  

  const popoverKeyword = (
    <Popover id="popover-keyword">
      <Popover.Body>
        Keyword selection from program content directly determines the Funding Programs linked to it.
        You may also opt not to type in any keyword and simply select a Type of Project and a Project Category independently.
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const projectId = project.id
    if (!codeTableData || !codeTableData[CODETABLE_TYPES.PROJECT_CATEGORY] || ((projectId === 'new' || typeof projectId === 'undefined') )) {
      // console.log('new project returning', {projectId})
      return;
    };
    const fullLookupData = [...codeTableData[CODETABLE_TYPES.PROJECT_CATEGORY]];
    const filteredCtegoryData = fullLookupData?.filter((c) => {
      if (c.parentId === +project.typeOfProjectId) {
        c.label = c.text;
        return c;
      }
      return false;
    });
    setProjectCategoryList(filteredCtegoryData);

    if(codeTableData[CODETABLE_TYPES.KEYWORD] && project.keywordIds) {
      const keywordIds:number[] = project.keywordIds.split(',').map(keywordId => +keywordId);
      const  selectedKeywordOptions = codeTableData[CODETABLE_TYPES.KEYWORD].filter(codeValue => {
        if(keywordIds.includes(codeValue.value)) {
          return codeValue;
        }
        return false;
      })
      setMultiSelectKeyword(selectedKeywordOptions)
    }
  }, [project.typeOfProjectId, codeTableData, project.keywordIds, project.id]);

  const navigate = useNavigate();

  return (
    <div className="project-edit-container layout-container-content d-flex flex-fill flex-column">
      <div className="d-flex flex-fill project-container">
        <main role="main" className="layout-main flex-fill">
          <Container>
            <div className="main">
              <div className="title-content">
                <TitleCard
                  title="Project Eligibility Questionnaire"
                  description="Complete this short questionnaire to receive a customized list of federal funding programs for which your project might be eligible."
                />
              </div>
              <div >
                <span className="req-field">Please refer to <a href="https://hraadvisors-my.sharepoint.com/:w:/p/imontojo/ESnly8HfNi9BiOw103gDjcEB59NpYNky6wne7Ng0m4pJ7Q" target='_blank'>this</a> document for definitions of terms used in the questionnaire below.</span>
              </div>
              <br />
              <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Row className="justify-content-md-center layout-main project-field-row">
                  <Col>
                    <Form.Group className="error-text">
                      <Form.Control
                        type="text"
                        placeholder="Project Name"
                        id="title"
                        name="title"
                        value={project.title}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center project-field-row">
                  <Col>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      multiple
                      // name="keywordId"
                      placeholder="Keyword"
                      labelKey="text"
                      onChange={(e: any) => handleKeywordMultiselect(e)}
                      options={codeTableData[CODETABLE_TYPES.KEYWORD] || []}
                      selected={multiSelectKeyword}
                    // labelKey={project.keywordId}
                    />
                  </Col>
                </Row>
                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverKeyword}>
                  <img className="keyword-tooltip" data-tip data-for="registerTip" src={img4} />
                </OverlayTrigger>
                <Row className="justify-content-md-center project-field-row">
                  <Col>
                    <SelectInput
                      inputTitle={"Type of Project "}
                      name="typeOfProjectId"
                      selectOptions={
                        codeTableData[CODETABLE_TYPES.PROJECT_TYPES] || []
                      }
                      value={project.typeOfProjectId}
                      propsValue={"value"}
                      propsLabel={"text"}
                      key={"id"}
                      onChange={(e: any) => 
                        handleTypeOfProjectChange(
                          e?.target?.value
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <SelectInput
                      inputTitle={"Project Category "}
                      name="projectCategoryId"
                      selectOptions={projectCategoryList || []}
                      value={project.projectCategoryId}
                      propsValue={"value"}
                      propsLabel={"text"}
                      key={"id"}
                      onChange={(e: any) =>
                        projectContext.handleProjectChange(
                          e?.target?.name,
                          e?.target?.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Form.Group>
                  <Row className="justify-content-md-center newprojects-projectlocation-implementingentity-mH-ali project-field-row">
                    <Col>
                      <SelectInput
                        inputTitle={"Project Location "}
                        name="locationId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.PROJECT_LOCATION] || []
                        }
                        value={project.locationId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                    <Col>
                      <SelectInput
                        inputTitle={"Implementing Entity "}
                        name="implementingEntityId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.IMPLEMENTING_ENTITY] ||
                          []
                        }
                        value={project.implementingEntityId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center project-field-row">
                    <Col>
                      <SelectInput
                        inputTitle={"Approximate Project Cost"}
                        name="projectCostId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.PROJECT_COST] || []
                        }
                        value={project.projectCostId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                    <Col>
                      <SelectInput
                        inputTitle={"Project Activity "}
                        name="eligibleActivityId"
                        selectOptions={codeTableData[CODETABLE_TYPES.PROJECT_ACTIVITY] || []}
                        value={project.eligibleActivityId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-start project-field-row">
                    <Col className="col-6">
                      <SelectInput
                        inputTitle={"Preparation Stage"}
                        name="preparationStageId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.PROJECT_STAGE] || []
                        }
                        value={project.preparationStageId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <br />
                </Form.Group>
                <ButtonPrimary className="add-btn" type="submit">
                  {" "}
                  CONTINUE{" "}
                </ButtonPrimary>
              </Form>
            </div>
          </Container>
        </main>
      </div>
    </div>
  );
}
