import Axios from 'axios';
import { sortData } from '../components/utilities/datatable';
import { Project } from '../models/project';
import { CODETABLE_TYPES, SelectOption } from './models/codetable';

export class BaseAPI {
  static getAPIBaseURI() {
    const BASE_API_URL = process.env.REACT_APP_API_URL;

    return BASE_API_URL;
  }

  static buildAPIURL(apiPath: string) {
    const baseURL = BaseAPI.getAPIBaseURI();
    let prefix = "";
    if (!apiPath?.startsWith("/")) {
      prefix = "/";
    }

    const apiUrl = `${baseURL}${prefix}${apiPath}`;

    return apiUrl;
  }
  static async login(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/login"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async users(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/user"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async addProjects(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/projects"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getCodeTable(typeCodes: CODETABLE_TYPES[]) {
    const typeCodesParam = typeCodes ? `&typeCodes=${typeCodes.join(",")}` : "";
    const apiPath = BaseAPI.buildAPIURL(
      `/code-table?excludeValues=false&includeInactive=false${typeCodesParam}`
    );
    return fetch(apiPath)
      .then((result) => result.json())
      .then((results) => {
        if (!results) {
          return;
        }
        const codeTableOptions: { [key: string]: SelectOption[] } = {};
        results.data.forEach((codeTable: any) => {
          if (codeTable.values && codeTable.values.length > 0) {
            const selectOptions: SelectOption[] = sortData(
              codeTable.values,
              "displaySequence",
              true
            ).map((codeValueItem) => {
              const codeValueOption = 
               {
                text: codeValueItem.name,
                value: codeValueItem.id,
                parentId: codeValueItem.parentId,
                label : codeValueItem.name,
              };
              if(codeTable.typeCode === CODETABLE_TYPES.PROJECT_CATEGORY) {
                codeValueOption['keywords'] = codeValueItem.keywords;
              }
              return codeValueOption;
            });
            codeTableOptions[codeTable.typeCode] = selectOptions;
          }
        });
        return codeTableOptions;
      });
  }

  static async getCodeTableValues(id: number, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/code-table-values/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getAllCodeTableValues(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/code-table-values"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getAllFundingProgram(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/funding-eligibility"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getAllFundingEligibleProgram(callback: any) {
    await Axios.get(
      BaseAPI.buildAPIURL("/funding-eligibility/eligible-programs/user"),
      {}
    )
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getProjects(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/projects"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async deleteProject(id: number ,callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/projects/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getProjectDetail(projectId: number, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/projects/${projectId}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getFundingEligibility(project: Project, callback: any) {
    const filterAttrs = [
      "implementingEntityId",
      "typeOfProjectId",
      "projectCategoryId",
      "eligibleActivityId",
      "locationId",
      "keywordIds",
    ];
    const params = filterAttrs.map((filterAttr) => {
      const filterValue = project[filterAttr] || undefined;
      return `${filterAttr}=${filterValue}`;
    });
    const paramString = params.join("&");
    await Axios.get(
      BaseAPI.buildAPIURL(`/funding-eligibility?${paramString}`),
      {}
    )
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getProjectComponents(pcaId: number, callback: any) {
    await Axios.get(
      BaseAPI.buildAPIURL(`/pca-taxonomy/project-component?pcaId=${pcaId}`),
      {}
    )
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getProjectPCADetail(projectId: number | string, callback: any) {
    // http://localhost:3000/pca/2?includeInactive&includeTaxonomyTree=1&includeQuestions=1&projectId=1

    await Axios.get(
      BaseAPI.buildAPIURL(
        `/pca/default?includeInactive&includeTaxonomyTree=1&includeQuestions=1&includeAnswers=1&projectId=${projectId}`
      ),
      {}
    )
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getProjectPCASummary(projectId: number | string, callback: any) {
    // http://localhost:3000/pca/2?includeInactive&includeTaxonomyTree=1&includeQuestions=1&projectId=1

    await Axios.get(
      BaseAPI.buildAPIURL(
        `/pca/default?includeInactive&includeTaxonomyTree=1&includeQuestions=1&includeAnswers=1&includeChoice=1&projectId=${projectId}`
      ),
      {}
    )
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getPriorityScores(projectId: number | string, callback: any) {
    await Axios.get(
      BaseAPI.buildAPIURL(`/projects/${projectId}/priorityscore`),
      {}
    )
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

   static async getContent(typeCode, callback: any) {
      // const typeCodeParam = typeCode ? `&typeCode=${typeCode.join(',')}` : ''
      
      await Axios.get(BaseAPI.buildAPIURL(`/content/${typeCode}`), {
      }).then(async (response) => {
         callback(response);
      })
         .catch(function (error) {
            if (error.response) {
               callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
         })
   }

  static async saveQuestions(answerdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/answer"), answerdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async submitContactForm(formdata: any, callback: any) {

    
    await Axios.post(BaseAPI.buildAPIURL("/contact-us"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }
}
