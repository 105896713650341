import { useContext, useEffect, useState } from "react";
import { Col, Container, OverlayTrigger, Popover, Row, Spinner, Table } from "react-bootstrap";
import ButtonPrimary from "../../components/buttons/buttons";
import { BaseAPI } from "../../providers/base-api";
import { useNavigate } from "react-router-dom";
import TitleCard from "../../components/title-card/titlecard";
import { IoIosArrowDown, IoIosArrowUp, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { sortDataProgram } from "../../utils/common-utils";
import "./fundingeligibility.css";
import { ArrowLeft } from "react-bootstrap-icons";


import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import { ProjectContextType, ProjectContext } from "../project/project";
import { Project } from "../../models/project";
import { formatCurrency } from "../../utils/common-utils";
import img4 from "../../assets/images/info-icon.png";
import ScrollToTop from "../../components/utilities/scroll";



export default function FundingEligibility() {

  const projectContext: ProjectContextType = useContext(ProjectContext)
  const [isSorting, setIsSorting] = useState(true)
  // const projectId = params && params.id ? +params.id : undefined;
  const [fundingData, setFundingData] = useState([]);
  const onSubmit = async () => {
    try {
      if (projectContext.project.id === 'draft' || !projectContext.project.id) {
        await projectContext.saveProject((savedProjectId) => {
          navigate(`/projects/${savedProjectId}/pca/intro`)
        });
      } else {
        navigate(`/projects/${projectContext.project.id}/pca/intro`)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const sortTableData = (order: boolean = false) => {
    setIsSorting(order)
    let data = sortDataProgram(fundingData, 'fundingProgramName', order ? 'asc' : 'desc');
    setFundingData(data);
  }

  useEffect(() => {
    // const prj = projectContext.project;
    // if (!((prj.eligibleActivityId && prj.implementingEntityId && prj.projectCategoryId && prj.typeOfProjectId) || prj.keywordId)) {
    //   return;
    // }

    const getFundingEligibility = async (project: Project) => {
      setIsLoading(true)
      try {
        await BaseAPI.getFundingEligibility(project, (result: any) => {
          setIsLoading(false)
          if (result.status === 200) {
            const enablePca = result.data?.results?.length > 0;
            projectContext.setEnablePca(enablePca)
            setFundingData(result.data);
          } else {
            console.log(result.message);
          }
        });
      } catch (e) {
        console.log(e);
      }
    };
  
    getFundingEligibility(projectContext.project);
  }, [projectContext.project, projectContext]);

  const navigate = useNavigate();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <strong>Disclaimer: </strong>Based on publicly available information from the federal government, your project could meet the criteria for eligibility for this funding program. HR&A cannot guarantee eligibility or funding for your project.
      </Popover.Body>
    </Popover>
  );



  return (
    <>
      <ScrollToTop />
      <div className="funding-eligibility-container layout-container-content d-flex flex-fill flex-column page-container">
        <div className="d-flex flex-fill ">
          <main role="main" className="layout-main flex-fill ">
            <Container>
              <div className="main">
                <TitleCard
                  title="Funding Eligibility"
                />
                <p className="funding-text">
                  The IFN Beta considers projects eligible for a funding program when they align with federally identified categories. The IFN Beta is a free tool that offers guidance for federal funding and project readiness assessment—a good first step when trying to understand what federal funding opportunities might align with your project. While HR&A cannot guarantee project funding or the comprehensiveness, accuracy, or timeliness of the information provided, we encourage you to contact us at <a href="mailto:infrastructure@hraadvisors.com">infrastructure@hraadvisors.com</a> if you need additional support with the next steps of your funding assessment and submission process.
                </p>
                
                <br></br>
                <Row className="justify-content-md-center layout-main funding-eligibility-container-tit-tbl-gap">
                  <Col>
                    {(!fundingData || fundingData.length === 0) && !isLoading ?
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col>
                          <p className="title-body funding-eligibility-nodata">Based on the information you provided, the IFN has not identified any federal funding opportunities.</p>
                        </Col>
                      </Row>
                      : ''}
                    {isLoading ? <Spinner className="funding-eligibility-spinner-loader-ftbl" animation="border" variant="info" /> : null}
                    {fundingData?.length > 0 &&
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col>
                          <Table hover className="table accordion funding-eligibility-table " id="fe-programs-table">
                            <thead className="funding-eligibility-container-tb-head" >
                              <tr>
                                <th scope="col" className="side-lef funding-eligibility-container-col-program-name">
                                  <span >Program Name{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)}
                                  /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)}
                                  />} </span>
                                </th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-type-of-project">Type of Project</th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-project-category">Project Category</th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-program-funding">Amount</th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-eligibility">
                                  <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
                                    <img className="two-layer-icon" alt="information icon" data-tip data-for="registerTip" src={img4} />
                                  </OverlayTrigger>
                                  Eligibility</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            {fundingData.map((result: any, index: number) => (
                              <tbody key={index}>
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#r${index}`}
                                  className="funding-eligibility-container-tb-body"
                                >
                                  <td className="funding-eligibility-container-tbl-hd-1" >{result?.fundingProgramName}</td>
                                  <td className="funding-eligibility-container-drop-body ">{result?.typeOfProjectName}</td>
                                  <td className="funding-eligibility-container-drop-body">{result?.projectCategoryName}</td>
                                  <td className="funding-eligibility-container-drop-body funding-eligibility-container-amount-r">
                                    {formatCurrency(result?.amount)}
                                  </td>
                                  <td>
                                    <ButtonPrimary className="funding-eligibility-container-btn-style funding-eligibility-container-btn-eligible-pad">
                                      Eligible
                                    </ButtonPrimary>
                                  </td>
                                  <td scope="row">
                                    <IoIosArrowDown className="fe-program-drp-down" />
                                    <IoIosArrowUp className="fe-program-drp-up" />
                                  </td>
                                </tr>
                                <tr
                                  className="collapse accordion-collapse funding-eligibility-container-accordian"
                                  id={`r${index}`}
                                  data-bs-parent=".table"
                                >
                                  <td colSpan={6}>
                                    <Row className="funding-eligibility-container-colspan-drop-body">
                                      <Col>
                                        <h4 className="funding-eligibility-container-drop-head">
                                          Eligible Recipients:
                                        </h4>
                                        <p className="funding-eligibility-container-drop-body funding-eligibility-container-elibible">
                                          {
                                            result?.eligibleRecipients
                                          }
                                        </p>
                                      </Col>
                                      {/* <Col>
                                        <h4 className="funding-eligibility-container-drop-head">Disclaimer:</h4>
                                        <p className="funding-eligibility-container-drop-body funding-eligibility-container-disclaimer">
                                          Cras mattis consectetur purus sit amet fermentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                        </p>
                                      </Col> */}
                                      <Row className="funding-eligibility-container-purpose-mt">
                                        <Col>
                                          <h4 className="funding-eligibility-container-drop-head">Purpose:</h4>
                                          <p className="funding-eligibility-container-drop-body funding-eligibility-container-drop-body-purposes">
                                            {result?.purpose}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {
                                            result?.link ?
                                              <ButtonPrimary className="funding-eligibility-container-btn-learn"
                                                href={result?.link}
                                                target='_blank'>
                                                LEARN MORE
                                              </ButtonPrimary> : null
                                          }
                                        </Col>
                                      </Row>
                                    </Row>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </Table>
                        </Col>
                        <Row className="funding-eligibility-container-btn-cot funding-row">
                        </Row>
                      </Row>
                    }
                  </Col>
                </Row>
                <Row className="bottom-padding">
                  <Col className="funding-eligibility-container-btn-back-align-top">
                    <a className="funding-eligibility-container-btn-back" href={`/projects/${projectContext.project.id || 'draft'}`} >
                      <ArrowLeft
                        className="arrow funding-eligibility-container-arrow-spacing"
                        color="#aeb0b5"
                        size={25}
                      />
                      Back
                    </a>
                  </Col>
                  <Col className="p-0">
                    <ButtonPrimary
                      className="funding-eligibility-container-btn-align"
                      onClick={onSubmit}
                    >
                      READINESS ASSESSMENT
                    </ButtonPrimary>

                  </Col>
                </Row>
              </div>
            </Container>
          </main>
        </div>
      </div>
    </>
  );
}

