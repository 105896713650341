import { Container, Row, Col, Form } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import ButtonPrimary from "../../components/buttons/buttons";
import "./login.css";
import { BaseAPI } from "../../providers/base-api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { LoginFormData } from "../../models/auth";

interface LoginPageProps {
  onLoginSuccessful: () => void;
  handleModelPopup: (event: any, type: string) => void;
  redirectTo?: string;
}

interface LoginFieldErrors {
  username?: string;
  password?: string;
  form?: string;
}

function LoginPage({ onLoginSuccessful, handleModelPopup, redirectTo }: LoginPageProps) {


  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false)

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState<LoginFieldErrors>({ username: '', password: '', form: '' })
  const [form, setForm] = useState<LoginFormData>({ username: '', password: '' });
  const { login, targetPath } = useAuth();


  const onFieldChange = (field, value) => {
    setForm(prevState => ({ ...prevState, [field]: value }))

    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })

  }

  const validateForm = () => {
    const { username, password } = form
    const newErrors: LoginFieldErrors = {}
   if (!username || username === '') newErrors.username = 'This field is required'
    if (!password || password === '') newErrors.password = 'This field is required'

    return newErrors
  }

  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    const redirectPath = redirectTo ? redirectTo : targetPath;

    axios.post(BaseAPI.buildAPIURL('/auth/login'), form)
      .then((res) => {
        if (res.status === 200) {

          login({
            email: form.username,
            token: res.data.access_token
          });
          if (redirectPath) {
            navigate(redirectPath || "/projects", { replace: true });
          }
          onLoginSuccessful();
        } else {
          setValidated(true)
          setErrors({ form: 'Invalid Email / Password' })

        }
      })
      .catch(error => {

        setErrorMessage(error?.response?.data?.message)
        setErrors({ form: 'Invalid Email / Password' })
        setHasError(true)
        setValidated(true)
      })
  }

  return (
    <>
      <Container className="login-container">
        <Row>
          <Col lg={5}>
            <h6 className="log">Log In</h6>
            <h3 className="subtitle">Log in to your Infrastructure Funding Navigator profile to save your projects, retrive previously created projects, and access the Project Readiness Assessment.</h3>
          </Col>
          <Col lg={7}>
            <Form
              noValidate

              onSubmit={handleSubmit}
            >
              <Row className="login-field-row">
                <Col sm={12}>
                  <Form.Group>

                    <Form.Control
                      className={`form-control input-lg email ${hasError ? 'is-invalid' : ''}`}
                      type="text"
                      placeholder="Email address *"
                      value={form.username}
                      onChange={(e: any) => onFieldChange('username', e.target.value)}
                      required
                      isInvalid={!!errors.username}

                    />
                    <Form.Control.Feedback type='invalid' className="form-control-feedback">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                </Col>
                </Row>
                <Row className="login-field-row">
                <Col sm={12}>
                  <Form.Group>
                    <Form.Control
                      className={`form-control input-lg pass ${hasError ? 'is-invalid' : ''}`}
                      type="password"
                      placeholder="Password *"
                      value={form.password}
                      onChange={(e: any) => onFieldChange('password', e.target.value)}
                      required
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type='invalid' className="form-control-feedback">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {errors.form &&
                  <div className="h6-align">
                    <span >Invalid Email address/Password</span>
                  </div>
                }
              </Row>

              <Row>
                <Col>
                  <div className="d-flex justify-content-between align-item-center btn-alignment">
                    <a
                      href="#login"
                      className="signup"
                      onClick={(event) => handleModelPopup(event, "signup")}
                    >
                      SIGN UP
                    </a>

                    <ButtonPrimary className="login log-in-modelpop-btn" type="submit" >
                      LOG IN &nbsp;
                      <ArrowRight className="arrow log-in-modelpop-arrow" size={26} />
                    </ButtonPrimary>


                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LoginPage;
