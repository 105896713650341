import { Container, Row, Col, Form } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { useState } from "react";
import "./sign-up.css";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import ButtonPrimary from "../../components/buttons/buttons";
import { BaseAPI } from "../../providers/base-api";
import { SignupFormData } from "../../models/auth";
import isEmail from 'validator/lib/isEmail';


interface SignupFieldErrors {
  firstName?: string;
  lastName?: string;
  organization?: string;
  position?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  heardThrough?: string;
  agreedToTerms?: string;
  form?: string;
}

const SignUpPage = (props: any) => {
  const [validated, setValidated] = useState(false);
  // const heardThrough = [
  //   { text: "Friends", value: "friends" },
  //   { text: "Ads", value: "ads" },
  //   { text: "Other", value: "other" },
  // ];

  const fields = [
    { field: 'firstName', required: true, length: 100, name: 'First Name' },
    { field: 'lastName', required: true, length: 100, name: 'First Name' },
    { field: 'email', required: true, length: 255, name: 'Email', type: 'email' },
    { field: 'password', required: true, length: 50, name: 'Password', type: 'password', matchWith: 'confirmPassword' },
    { field: 'confirmPassword', required: true, length: 50, name: 'Confirm Password', type: 'password', matchWith: 'password' },
    { field: 'agreedToTerms', name: 'Privacy Policy & Terms', type: 'boolean' },
  ]

  const [successmsg, setSuccessMessage] = useState(false);
  const [errors, setErrors] = useState<SignupFieldErrors>({
    firstName: "",
    lastName: "",
    organization: "",
    position: "",
    email: "",
    password: "",
    confirmPassword: "",
    heardThrough: ""
  })

  const [form, setForm] = useState<SignupFormData>({
    firstName: "",
    lastName: "",
    organization: "",
    position: "",
    email: "",
    password: "",
    confirmPassword: "",
    heardThrough: "", agreedToTerms: true
  });

  const validateForm = () => {
    // const { firstName, lastName, organization, position, email, password, confirmPassword } = form
    const newErrors: SignupFieldErrors = {}
    fields.forEach(fieldDef => {
      const fieldValue = form[fieldDef.field];
      // console.log(`Validating ${fieldDef.field}[${fieldValue}]`)
      if (fieldDef.required && (!fieldValue || fieldValue === '')) {
        newErrors[fieldDef.field] = 'This field is required';
        return;
      }
      if (fieldDef.field === 'agreedToTerms' && (!fieldValue || fieldValue === false)) {
        newErrors[fieldDef.field] = 'You must agree to terms to proceed';
        return;
      }
      if(fieldDef.type === 'email' && !isEmail(fieldValue)) {
        newErrors[fieldDef.field] = 'Valid email required'
      }
      if(fieldDef.type === 'password') {
        const matchingFieldValue = form[fieldDef.matchWith];
        if(fieldValue !== matchingFieldValue) {
          newErrors[fieldDef.field] = 'Password and confirm password must match'
        }
      }
      
    })

    return newErrors
  }

  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (fieldName, value, event?: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({ ...prevState, [fieldName]: value }));

    if (errorMessage) {
      // Once form starts changing hide error
      setErrorMessage("");
    }

    if (["password", "confirmPassword"].includes(fieldName)) {
      const password = fieldName === "password" ? value : form.password;
      const confirmPassword =
        fieldName === "confirmPassword" ? value : form.confirmPassword;
      if (
        password?.length > 0 &&
        confirmPassword?.length > 0 &&
        password !== confirmPassword
      ) {
        if(fieldName === 'confirmPassword')
        event.target.setCustomValidity('Password and confirm password must match')
        // setErrors((prevState) => ({ ...prevState, password: 'Password and confirm password must match'}))
        setErrors((prevState) => ({ ...prevState, confirmPassword: 'Password and confirm password must match'}))
        // setHasPasswordMismatch(true);
      } else {
        event.target.setCustomValidity('')
        // setErrors((prevState) => ({ ...prevState, password: ''}))
        setErrors((prevState) => ({ ...prevState, confirmPassword: ''}))
        // setHasPasswordMismatch(false);
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) {
      // console.log(errors)
      return;
    }

    const formElement = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (formElement.checkValidity() === false) {
      setValidated(true);
      // console.log('form invalid')
      return;
    }

    try {
      await BaseAPI.users(form, (result: any) => {
        if(result?.response?.data?.errors?.username) {
          setErrors({email: result?.response?.data?.errors?.username})
        }
        if (result.status === 201) {
          setSuccessMessage(true);
          setTimeout(() => {
            props?.changeToLoginModel("login");
          }, 1000);
        } else {
        }
      });
    } catch (error) {
      if(error?.response?.data?.errors?.username) {
        setErrors({email: error?.response?.data?.errors?.username})
      }
      // setErrorMessage(error?.response?.data?.errors?.username);
    }
    setValidated(true);
  };
  return (
    <div className="signup-container layout-container-content d-flex flex-fill flex-column">
      <div className="d-flex flex-fill">
        <main role="main" className="layout-main flex-fill">
          <Container>
            <div className="main">
              <div className="row" style={{ marginTop: "-3rem" }}>
                <div className="col-1"></div>
                <div
                  className="col-3 content"
                  style={{
                    marginLeft: "-2.5rem",
                    marginRight: "4rem",
                    backgroundColor: "white",
                    height: "0px",
                  }}
                >
                  <h1 className="maintitle">Sign Up</h1>
                  <h3 className="subtitle">
                    Sign up to access the beta IFN tool that enables your
                    community to better understand if an infrastructure project
                    might be eligible for federal funding and assess its
                    readiness for implementation.
                  </h3>
                </div>
                <div className="col-7 form">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row className="mb-3 signup-row">
                      <Form.Group as={Col} md="6">
                        <Form.Control
                          className="firstName"
                          type="text"
                          placeholder="First name *"
                          required
                          value={form.firstName}
                          name="firstName"
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                        <Form.Control.Feedback type='invalid' className="signup-form-control-feedback">
                          {errors?.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <Form.Control
                          className="lastName"
                          type="text"
                          placeholder="Last name *"
                          required
                          name="lastName"
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                        <Form.Control.Feedback type='invalid' className="signup-form-control-feedback">
                          {errors?.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3 signup-row">
                      <Form.Group as={Col} md="6">
                        <Form.Control
                          type="text"
                          placeholder="Organization"
                          value={form.organization}
                          name="organization"
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <Form.Control
                          className="position"
                          type="text"
                          placeholder="Title"
                          value={form.position}
                          name="position"
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 signup-row">
                      <Col>
                        <Form.Control
                          type="email"
                          placeholder="Email address *"
                          required
                          value={form.email}
                          name="email"
                          isValid={validated && (!errors.email || errors.email.length === 0)}
                          isInvalid={errors?.email && errors.email.length > 0}
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                        <Form.Control.Feedback type='invalid' className="signup-form-control-feedback">
                          {errors?.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row className="mb-3 signup-row">
                    <Col>
                      <Form.Group >
                        <Form.Control
                          type="password"
                          placeholder="Password *"
                          required
                          value={form.password}
                          name="password"
                          isValid={validated && (errors?.password?.length === 0)}
                          isInvalid={errors?.password?.length > 0}
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value, e)
                          }
                          />
                        <Form.Control.Feedback type='invalid' className="signup-form-control-feedback">
                          {errors?.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    </Row>
                    <Row className="mb-3 signup-row">

                    <Col>
                      <Form.Group>
                        <Form.Control
                          type="password"
                          placeholder="Confirm password *"
                          required
                          value={form.confirmPassword}
                          name="confirmPassword"
                          isValid={validated && (!errors.confirmPassword || errors.confirmPassword.length === 0)}
                          isInvalid={errors?.confirmPassword && errors.confirmPassword.length > 0}
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value, e)
                          }
                        />
                        <Form.Control.Feedback type='invalid' className="signup-form-control-feedback">
                          {errors?.confirmPassword}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    </Row>

                      { errorMessage ? (
                    <Row>
                        <span className="err-msg">{errorMessage}</span>
                    </Row>
                      ) : null}
                    <Row className="mb-3 justify-content-md-center">
                      <Col>
                        <div>
                          <Form.Check>

                            <Form.Check.Input
                              type="checkbox"
                              defaultChecked={form.agreedToTerms}
                              value={form.agreedToTerms ? 1 : 0}
                              name="agreedToTerms"
                              required
                              onChange={(e: any) =>
                                handleChange(e.target.name, e.target.checked)
                              }
                            ></Form.Check.Input>
                            <Form.Check.Label
                              style={{ paddingLeft: "7px", marginTop: "3px" }}
                            >
                              I have read and agree with the{" "}
                              <a href="https://www.hraadvisors.com/privacy-policy/" rel="noreferrer" className="link-primary" target="_blank">
                                HR&A Privacy Policy
                              </a> and&nbsp;
                              <a href="https://www.hraadvisors.com/terms-of-use/" rel="noreferrer" className="link-primary" target="_blank">
                                Terms and Conditions
                              </a>
                            </Form.Check.Label>
                            <Form.Control.Feedback type='invalid' className="signup-form-control-feedback">
                              {errors?.agreedToTerms}
                            </Form.Control.Feedback>
                          </Form.Check>
                        </div>

                        <div>
                          <FormCheckInput type="checkbox"></FormCheckInput>
                          <FormCheckLabel
                            style={{ paddingLeft: "7px", marginTop: "3px" }}
                          >
                            I want to learn about new features and receive
                            important announcements.
                          </FormCheckLabel>
                        </div>
                      </Col>
                    </Row>
                    <div className="row">
                      <div className="col sign-up-loginbtn-mrg">
                        <a
                          href="#login"
                          className="sign-up-login-bt"
                          onClick={(event) =>
                            props?.handleModelPopup(event, "login")
                          }
                        >
                          LOG IN
                        </a>
                      </div>
                      <div className="col">
                        <ButtonPrimary className="ssign" type="submit">
                          {" "}
                          SIGN UP &nbsp;
                          <ArrowRight className="arrow" size={28} />{" "}
                        </ButtonPrimary>
                      </div>
                    </div>
                  </Form>
                  {successmsg && (
                    <div className="snippet" data-title=".dot-pulse">
                      <div className="stage">
                        <span className="suc-label">
                          {" "}
                          Signup Completed Successfully Redirect to the Login
                          Page
                        </span>
                        <div className="dot-pulse"></div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    </div>
  );
};

export default SignUpPage;
