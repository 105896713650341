import React from 'react'
import "./footer.css";
function Footer() {
  return (
    <><div className=''>
     
         				<p className="h6 text-align"> © 2022 HR&A Advisors, Inc. All rights reserved.</p>
      </div>
      </>
    
  )
}

export default Footer