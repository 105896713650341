import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/home";
import ProjectPage from "./pages/project/project";
import FundingEligibility from "./pages/fundingeligibility/fundingeligibility";
import PCAIntroductionPage from "./pages/pca-introduction/pca-introduction";
import Newprojects from "./pages/project/newprojects";
import PcaQuestions from "./pages/pca-questions/pca-questions";
import ProjectListingPage from "./pages/project/project-listing";
import ScorePrioritySummary from "./pages/score-summary/scorecard-chart";
import ProtectedRoute from "./components/auth/protected-route";
import EligibleProgram from "./pages/eligibleprograms/eligibleprogram";
import { StrictMode } from "react";

export enum EntityRoutes {
  PCA_QUESTIONS = "/projects/:projectId/pca/questions",
}
export function GlobalRoutesMap() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/projects/*" element={
        <StrictMode>
          <ProjectPage />
        </StrictMode>
      }>
        <Route
          index
          element={
            <ProtectedRoute>
              <ProjectListingPage />
            </ProtectedRoute>
          }
        />
        <Route path="new" element={<Newprojects />} />
        <Route path="draft" element={<Newprojects />} />
        <Route
          path=":id"
          element={
            <ProtectedRoute>
              <Newprojects />
            </ProtectedRoute>
          }
        />
        <Route path=":id/programs" element={<FundingEligibility />} />
        <Route
          path=":id/pca"
          element={
            <ProtectedRoute>
              <PCAIntroductionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/pca/questions"
          element={
            <ProtectedRoute>
              <PcaQuestions />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/pca/intro"
          element={
              <PCAIntroductionPage />
          }
        />
        <Route
          path=":id/summary"
          element={
            <ProtectedRoute>
              <ScorePrioritySummary />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/" element={<HomePage />} />
      <Route path="/programs" element={<EligibleProgram />} />
    </Routes>
  );
}

export default GlobalRoutesMap;
