import { useEffect, useState } from "react";
import { Col, Container, OverlayTrigger, Popover, Row, Spinner, Table } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { IoIosArrowDown, IoIosArrowUp, IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import ButtonPrimary from "../../components/buttons/buttons";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import { useAuth } from "../../hooks/useAuth";
import "./eligibleprogram.css";
import { formatCurrency } from "../../utils/common-utils";
import img4 from "../../assets/images/info-icon.png";
import { sortDataProgram } from "../../utils/common-utils";
import SignupOrLogin from "../../components/Modal/SignupOrLogin";




export default function EligibleProgram() {
  useEffect(() => { });

  const [isSignUp, setIsSignUp] = useState(false);
  const { user } =
    useAuth();
  const [fundingProgram, setFundingProgram] = useState([]);
  const [fundingEligibleProgram, setFundingEligibleProgram] = useState([]);
  const [modalType, setModalType] = useState("");
  const [isSorting, setIsSorting] = useState(true)
  const [isSortingData, setIsSortingData] = useState(true)

  const [inProgress, setInProgress] = useState({ eligible: false, all: false });


  const getAllFundingProgram = async () => {
    try {
      setInProgress((prev) => { return { ...prev, all: true } })
      await BaseAPI.getAllFundingProgram(async (result: any) => {
        if (result.status === 200) {
          // let data = await sortDataProgram(result.data.results, 'fundingProgramName', 'asc');
          setFundingProgram(result.data);
        } else {
          console.log(result.message);
        }
        setInProgress((prev) => { return { ...prev, all: false } })

      });
    } catch (e) {
      console.log(e);
      setInProgress((prev) => { return { ...prev, all: false } })

    }
  };
  const getAllFundingEligibleProgram = async () => {
    try {
      setInProgress((prev) => { return { ...prev, eligible: true } })
      await BaseAPI.getAllFundingEligibleProgram((result: any) => {
        if (result.status === 200) {
          setFundingEligibleProgram(result.data);
        } else {
          console.log(result.message);
        }
        setInProgress((prev) => { return { ...prev, eligible: false } })

      });
    } catch (e) {
      console.log(e);
      setInProgress((prev) => { return { ...prev, eligible: false } })
    }
  };

  const sortTableData = (order: boolean = false) => {
    setIsSorting(order)
    let data = sortDataProgram(fundingProgram, 'fundingProgramName', order ? 'asc' : 'desc');

    setFundingProgram(data);
  }

  const sortTableDataPrograms = (order: boolean = false) => {
    setIsSortingData(order)
    let data = sortDataProgram(fundingEligibleProgram, 'fundingProgramName', order ? 'asc' : 'desc');

    setFundingEligibleProgram(data);
  }

  useEffect(() => {
    if (user) {
      setIsSignUp(false);
      getAllFundingEligibleProgram();
    } else {
      setIsSignUp(true);
    }
    getAllFundingProgram();
  }, [user]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <strong>Disclaimer: </strong>Based on publicly available information from the federal government, your project could meet the criteria for eligibility for this funding program. HR&A cannot guarantee eligibility or funding for your project.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className=" d-flex flex-fill flex-column">
        <main role="main" className="layout-main flex-fill eligible">
          <Container>
            <div className="main">
              <div className="eligible-programs-heading">
                <TitleCard
                  title="Eligible Programs"
                  description="Browse federal funding below or sign up to learn more about your specific project's eligibility and readiness to access funding."
                />
              </div>
              {isSignUp ? (
                <Row className="eligible-programs-btnsign">
                  <Col>
                    <ButtonPrimary
                      className="eligible-program-signup"
                      onClick={(event) => setModalType("login")}
                    >
                      SIGN UP &nbsp;
                      <ArrowRight className="arrow" size={28} />
                    </ButtonPrimary>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-md-center layout-main funding-eligibility-container-tit-tbl-gap">
                  <Col>
                    {inProgress.eligible ? (
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col className="title-body-main">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Col>
                      </Row>
                    ) : (!fundingEligibleProgram || fundingEligibleProgram.length === 0) ? (
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col className="title-body-main">
                          <p className="title-body">
                            There are no matching eligible programs
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="eligible-program-container-tbl-design">
                        <Table className="table accordion table-heading" id="eligible-programs-table">
                          <thead className="eligibility-program-table-head-style">
                            <tr>
                              <th
                                scope="col"
                                className="eligible-program-programname"
                                style={{
                                  paddingLeft: "30px",
                                  height: "25px",
                                }}
                              >
                                <span >Program Name{isSortingData ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableDataPrograms(false)}
                                /> : <IoMdArrowDropdown className="sort-data"
                                // onClick={() => sortTableDataPrograms(true)}
                                />} </span>
                              </th>
                              <th
                                scope="col"
                                className="eligible-program-typeofproject"
                              >
                                Type of Project
                              </th>
                              <th
                                scope="col"
                                className="eligible-program-projectcategory"
                              >
                                Project Category
                              </th>
                              <th scope="col" className="eligible-program-amount">
                                Amount
                              </th>
                              <th
                                scope="col"
                                className="eligible-program-eligibility"
                                style={{
                                  paddingTop: "13px",
                                }}
                              >
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
                                  <img className="two-layer-icon" data-tip data-for="registerTip" src={img4} />
                                </OverlayTrigger>
                                Eligibility
                              </th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          {fundingEligibleProgram.map(
                            (result: any, index1: number) => (
                              <tbody key={index1} >
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#r${index1}`}
                                  className="eligible-program-tb-body"
                                >
                                  <td
                                    className="ep-name"
                                    style={{ paddingLeft: "30px" }}
                                  >
                                    {result?.fundingProgramName}
                                  </td>

                                  <td className="ep-body-top ">
                                    {result?.typeOfProjectName}
                                  </td>
                                  <td className="ep-body-pc">
                                    {result?.projectCategoryName}
                                  </td>

                                  <td className="ep-body-amt">
                                    {formatCurrency(result?.amount)}
                                  </td>
                                  <td>
                                    <ButtonPrimary
                                      className=" eligible-program-container-btn-style-1 eligible-program-container-btn-eligible-pad"
                                      style={{ textAlign: "center" }}
                                    >
                                      Eligible
                                    </ButtonPrimary>
                                  </td>

                                  <td scope="row">
                                    <IoIosArrowDown className="eligible-program-drp-down" />
                                    <IoIosArrowUp className="eligible-program-drp-up" />
                                  </td>
                                </tr>
                                <tr
                                  //data-bs-toggle="collapse"
                                  className="ep-container-accordian collapse accordion-collapse"
                                  id={`r${index1}`}
                                  data-bs-parent="#eligible-programs-table"
                                >
                                  <td colSpan={12} className="div-hover" style={{ borderColor: "white" }}>
                                    <Row className="recipients">
                                      <Col>
                                        <h4 className="eligible-program-recipients">
                                          Eligible Recipients:
                                        </h4>
                                        <p className="eligible-program-recipients-drop-down">
                                          {result?.eligibleRecipients}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row className="recipients">
                                      <Col>
                                        <h4 className="eligible-program-purpose">
                                          Purpose:
                                        </h4>
                                        <p className="eligible-program-purpose-drop-body">
                                          {result?.purpose}
                                        </p>
                                      </Col>

                                    </Row>
                                    <Row className="recipients button-padding">
                                      <Col>
                                        {
                                          result?.link ?
                                            <ButtonPrimary className="eligibility-container-btn-learn"
                                              href={result?.link}
                                              target='_blank'

                                            >
                                              LEARN MORE
                                            </ButtonPrimary> : null
                                        }
                                      </Col>
                                    </Row>

                                  </td>
                                  {/* <td colSpan={2} style={{ borderColor: "white" }}>
                                    <Row style={{ paddingTop: "4px" }}>
                                      <Col style={{ marginTop: "16px" }}>
                                        <h4 className="eligible-program-disclaimer">
                                          Disclaimer:
                                        </h4>
                                        <p className="eligible-program-disclaimer-drop-down">
                                          Cras mattis consectetur purus sit amet fermentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                        </p>
                                      </Col>
                                    </Row>
                                  </td> */}
                                </tr>
                              </tbody>
                            )
                          )}
                        </Table>
                        <div className="eligible-content">
                          <p className="eligible-description">
                            To learn more about HR&A’s practice areas and seek assistance for your project, contact us at <a href="mailto:infrastructure@hraadvisors.com">infrastructure@hraadvisors.com</a>.
                            </p>
                        </div>
                      </Row>
                    )}


                  </Col>
                </Row>
              )}

              <Row className="eligible-programs-subheading">
                <TitleCard
                  title="All Federal Funding Programs"
                  description="This comprehensive list is regularly updated to reflect all federal infrastructure funding programs."
                />
              </Row>

              <Row className="eligible-program-container-tbl-design">
                <Table className="table-heading" id="all-programs-table">

                  <thead className="eligibility-program-table-head-style">
                    <tr>
                      <th
                        scope="col"
                        className="eligible-program-programname"
                        style={{
                          paddingLeft: "30px",
                          height: "25px",
                        }}
                      >
                        <span >Program Name{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)}
                        /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)}
                        />} </span>
                      </th>
                      <th
                        scope="col"
                        className="eligible-program-typeofproject"
                      >
                        Type of Project
                      </th>
                      <th
                        scope="col"
                        className="eligible-program-projectcategory"
                      >
                        Project Category
                      </th>
                      <th scope="col" className="eligible-program-amount">
                        Amount
                      </th>


                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {inProgress.all ? (
                    <tbody key='all-programs-loading'>
                      <tr className="eligible-program-tb-body">
                        <td className="ep-name" colSpan={5}>
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading... </span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  ) :
                    (fundingProgram.map((result: any, index2: number) => (
                      <tbody key={index2}>
                        <tr
                          data-bs-toggle="collapse"
                          data-bs-target={`#t2-r${index2}`}
                          className="eligible-program-tb-body"
                        >
                          <td className="ep-name" style={{ paddingLeft: "30px" }}>
                            {result?.fundingProgramName}
                          </td>

                          <td className="ep-body-top ">
                            {result?.typeOfProjectName}
                          </td>
                          <td className="ep-body-pc">
                            {result?.projectCategoryName}
                          </td>
                          <td className="ep-body-amt">
                            {formatCurrency(result?.amount)}
                          </td>


                          <td scope="row">
                            <IoIosArrowDown className="eligible-program-drp-down" />
                            <IoIosArrowUp className="eligible-program-drp-up" />
                          </td>
                        </tr>
                        <tr
                          // data-bs-toggle="collapse"
                          className="collapse ep-container-accordian accordion-collapse"
                          id={`t2-r${index2}`}
                          data-bs-parent="#all-programs-table"
                        >
                          <td colSpan={6} className="div-hover" style={{ borderColor: "white" }}>
                            <Row className="recipients">
                              <Col>
                                <h4 className="eligible-program-recipients">
                                  Eligible Recipients:
                                </h4>
                                <p className="eligible-program-recipients-drop-down">
                                  {result?.eligibleRecipients}
                                </p>
                              </Col>
                            </Row>
                            <Row className="recipients">
                              <Col>
                                <h4 className="eligible-program-purpose">
                                  Purpose:
                                </h4>
                                <p className="eligible-program-purpose-drop-body">
                                  {result?.purpose}
                                </p>
                              </Col>
                            </Row>
                            <Row className="recipients button-padding">
                              <Col>
                                {
                                  result?.link ?
                                    <ButtonPrimary className="eligibility-container-btn-learn"
                                      href={result?.link}
                                      target='_blank'>
                                      LEARN MORE
                                    </ButtonPrimary> : null
                                }
                              </Col>
                            </Row>
                          </td>
                          {/* <td colSpan={2} style={{ borderColor: "white" }}>
                            <Row style={{ paddingTop: "4px" }}>
                              <Col style={{ marginTop: "16px" }}>
                                <h4 className="eligible-program-disclaimer">
                                  Disclaimer:
                                </h4>
                                <p className="eligible-program-disclaimer-drop-down">
                                  Cras mattis consectetur purus sit amet fermentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                </p>
                              </Col>
                            </Row>
                          </td> */}
                        </tr>
                      </tbody>
                    )))}
                </Table>
              </Row>
            </div>
          </Container>
        </main>
      </div>
      <SignupOrLogin modalType={modalType} setModalType={setModalType}></SignupOrLogin>
      {/* 
      <ModelWindow
        show={showState}
        onHide={() => setShowState(false)}
        body={
          showState && modalType === "signup" ? (
            <SignUpPage handleModelPopup={handleModelPopup} />
          ) : (
            <LoginPage
              handleModelPopup={handleModelPopup}
              onLoginSuccessful={onLoginSuccessful}
            />
          )
        }
        contentClassName={
          modalType === "signup" ? "dialogSignUp" : "contentClassName"
        }
        dialogClassName="dialogClassName"
      /> */}
    </>
  );
}
