import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { BaseAPI } from "../../providers/base-api";
import ProjectHeader from "../../components/project-header/project-header";
import ProjectSidebar from "../../components/project-sidebar/project-sidebar";
import { PCA, Project, ResponseWithData } from "../../models/project";
import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { nanoid } from 'nanoid'
import "./project.css";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";

export type ProjectContextType = {
  project: Project | null;
  projectPCA?: PCA | null;
  hasUnansweredQuestions?: boolean;
  setTitle: (title: string) => void;
  handleProjectChange: (name: string, value: any) => void;
  currentCriteriaId?: number;
  setCurrentCriteria?: (componentId: number, criteriaId: number) => void;
  currentProjectComponentId?: number;
  pcaTs?: number;
  setPcaTs?: (ts: number) => void;
  saveProject?: (callback) => Promise<any>;
  enablePca?: boolean;
  setEnablePca?: (enable: boolean) => void;
};

export const ProjectContext = React.createContext({
  project: null,
  
  setTitle: (title: string) => { },
  handleProjectChange: (name: string, value: any) => { }
});

export const useProject = () => {
  return useContext(ProjectContext);
};

const ProjectPage = () => {
  const { user } = useAuth()
  const currentLocation = useLocation();
  let [searchParams] = useSearchParams();
  const criteriaIdParam = +searchParams.get('cid');
  const pComponentIdParam = +searchParams.get('pcid');
  const params = useParams();
  
  const routeData = useMemo(() => {
    const routeDataObj =  {
      isIndexRoute: currentLocation.pathname === '/projects',
      id: params?.id || currentLocation.pathname.endsWith('draft') ? 'draft': 'new',
      isNew: typeof params?.id === 'string' && params.id === 'new',
      isDraft: (typeof params?.id === 'string' && params.id === 'draft' )|| currentLocation.pathname.endsWith('draft'),
      currentProjectComponentId: pComponentIdParam,
      currentCriteriaId: criteriaIdParam
    }
    return routeDataObj
  }, [criteriaIdParam, currentLocation.pathname, pComponentIdParam, params?.id]);
  
  const navigate = useNavigate();
  
  // const [projectId, setProjectId] = useState(routeData.id);
  const projectId = (params && params.id) ? params.id : currentLocation.pathname.endsWith('draft') ? 'draft': 'new';
  
  const defaultData: Project = useMemo(
    () => {
      const projectData: Project = { title: 'Untitled Project', createdAt: new Date()};
      projectData.id = projectId //(params && params.id) ? params.id : 'new';
      // if (params?.id) {
      //   projectData.id = projectId
      // }
      return projectData
    }
    , [projectId]);

  const [pcaTs, setPcaTs] = useState(0)
  const [projectPCA, setProjectPCA] = React.useState<PCA | null>(null);
  const [hasUnansweredQuestions,setHasUnansweredQuestions] = useState(false);
  const [project, setProject] = React.useState<Project | null>(defaultData);
  const [currentProjectComponentId, setCurrentProjectComponentId] = useState(routeData.currentProjectComponentId);
  const [currentCriteriaId, setCurrentCriteriaId] = useState(routeData.currentCriteriaId);
  const [enablePca, setEnablePca]  = useState(false)

  const projectContext: ProjectContextType = useMemo(
    () => {
      const handleProjectChange = (name, value) => {
        setProject(prevState => ({ ...prevState, [name]: value }))
        
      
      }
      const setTitle = (title: string) => {
        setProject({ title: title })
      }
      
      const setCurrentCriteria = (componentId: number, criteriaId: number) => {
        setCurrentCriteriaId(criteriaId);
        setCurrentProjectComponentId(componentId);
      }

      const saveProject = async (callback) => {
        const payload: Project = {
          title: project.title  || 'Untitled Project',
          locationId: project.locationId || undefined,
          keywordIds: project.keywordIds || undefined,
          implementingEntityId: project.implementingEntityId || undefined,
          typeOfProjectId: project.typeOfProjectId || undefined,
          projectCategoryId: project.projectCategoryId || undefined,
          eligibleActivityId: project.eligibleActivityId || undefined,
          projectCostId: project.projectCostId || undefined,
          siteTypeId: project.siteTypeId || undefined,
          preparationStageId: project.preparationStageId || undefined
        };
        if (!['new','draft'].includes(`${project.id}`) && !Number.isNaN(project.id)) {
          payload.id = project.id;
        }
        if (!user) {
          const newProjectId = 'draft'
          payload.version = nanoid()
          localStorage.setItem(newProjectId, JSON.stringify(payload))
          callback(newProjectId)
          return;
        }
        await BaseAPI.addProjects(payload, (result: any) => {
          if (result.status === 201) {
            const newProjectId = result?.data?.id;
            handleProjectChange('id', result?.data?.id)
            // localStorage.setItem("programData", JSON.stringify(result.data));
            // navigate(`/projects/${result?.data?.id}/programs`);
            callback(newProjectId);
            // return project;
            // handleClick(`/projects/1/programs`);
          } else {
            console.log(result.message);
          }
        });
      }

      return {
        project, projectPCA, handleProjectChange,
        setTitle,hasUnansweredQuestions,
        currentCriteriaId: currentCriteriaId,
        currentProjectComponentId: currentProjectComponentId,
        setCurrentCriteria, pcaTs, setPcaTs, saveProject,
        enablePca,
        setEnablePca
      }
    },
    [project, projectPCA, hasUnansweredQuestions, currentCriteriaId, currentProjectComponentId, pcaTs, enablePca, user]
  );

  useEffect(() => {
    setCurrentCriteriaId(routeData.currentCriteriaId)
    setCurrentProjectComponentId(routeData.currentProjectComponentId)
  }, [routeData.currentCriteriaId, routeData.currentProjectComponentId])

  useEffect(() => {
    if (!routeData.isIndexRoute) {
      if ((projectId === 'new' || typeof projectId === 'undefined')) {
        // && (typeof projectId === 'string' && projectId !== 'new')
        setProject({ title: 'Untitled Project', createdAt: new Date(), id: 'draft'});
        localStorage.removeItem('draft')
        // console.log('project.setDefault', projectContext.project)
        return;
      }
      if (routeData.isDraft) {
        const projectDataFromLocal = localStorage.getItem('draft');
        if(projectDataFromLocal) {
          const lprj:Project = JSON.parse(projectDataFromLocal)
          if(project.version === lprj.version) {
            // console.log('have same version, do nothing.')
            return;
          }
          const retrievedProjectState: Project = {...lprj}
          retrievedProjectState.id = 'draft'
          retrievedProjectState.title = retrievedProjectState.title || 'Untitled Project'
          setProject(retrievedProjectState)
        }
        return;
      }
      
      if (!user) {
        return;
      }
      try {

        if (!Number.isNaN(projectId)) {
          BaseAPI.getProjectDetail(+projectId, (result: any) => {
            if (result.status === 200) {
              const prj = result.data;
              prj.typeOfProjectId = prj.typeOfProjectId || ''
              prj.projectCategoryId = prj.projectCategoryId || ''
              prj.eligibleActivityId = prj.eligibleActivityId || ''
              prj.projectCostId = prj.projectCostId || ''
              prj.implementingEntityId = prj.implementingEntityId || ''
              prj.locationId = prj.locationId || ''
              prj.preparationStageId = prj.preparationStageId || ''
              prj.keywordIds = prj.keywordIds || ''
              
              setProject(result.data);
              setEnablePca(true)
              // projectContext.project = result.data
            } else {
              setProject(defaultData)
              // projectContext.project = defaultData

              console.log(result.message);
            }
          });
        } else {
          setProject(defaultData);
          // projectContext.project = defaultData
        }
      } catch (e) {
        console.log(e);
        setProject(defaultData);
      
      }
    }
  }, [routeData.isIndexRoute, defaultData, user, routeData.isDraft, routeData.id, projectId, project.version]);

  const getProjectPCADetailFn = useCallback(async () => {
    try {
      if ((['draft','new'].includes (projectId) || Number.isNaN(projectId))) {
        setProjectPCA(null)
        return;
      }

      await BaseAPI.getProjectPCADetail(projectId || 1, (result: ResponseWithData<PCA>) => {
        if (result.status === 200) {
          const pcaDetail = result.data;
          pcaDetail.pcaTs = pcaTs;
          if (pcaDetail.pcataxonomy) {
            let questionIndex = 0;
            pcaDetail.questionList = []
            pcaDetail.pcataxonomy.forEach(pComp => {
              pComp.children.forEach(criteria => {
                criteria.questionCount = 0
                criteria.answerCount = 0
                criteria.children.forEach(subCriteria => {
                  if (subCriteria.questionItems) {
                    criteria.questionCount += subCriteria.questionItems.length;
                    subCriteria.questionItems.forEach(question => {
                      questionIndex++;
                      pcaDetail.questionList.push(question)
                      question.questionIndex = questionIndex;
                      question.subCriteria = subCriteria;
                      if (question.projectAnswer) {
                        criteria.answerCount++;
                      }
                      else {
                        pcaDetail.hasUnansweredQuestions= true;
                        setHasUnansweredQuestions(true)
                      }
                      question.criteria = criteria;
                      question.projectComponentId = pComp.id;
                    })
                  }
                })
              })
            });

          }
          setProjectPCA(pcaDetail)
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
      setProject(defaultData);

    }
  }, [projectId, defaultData, pcaTs])

  useEffect(() => {
    if (routeData.isIndexRoute) {
      return
    }
    if ((projectId === 'new' || typeof projectId === 'undefined')) {
      const newProjectId = 'draft'
      navigate(`/projects/${newProjectId}`, { replace: true })
      return;
    }

  }, [routeData.isIndexRoute, projectId, navigate]);

  useEffect(() => {
    if (routeData.isIndexRoute) {
      return
    }
    
    if(projectId === 'draft' || Number.isNaN(projectId)) {
      setProjectPCA(null)
      return;
    }
    if (!user) {
      return;
    }
    getProjectPCADetailFn()

  }, [routeData.isIndexRoute, getProjectPCADetailFn, user, projectId]);

  if (routeData.isIndexRoute) {
    return (
      <Outlet />
    )
  }
  return (
    <div className="project-edit-container layout-container-content d-flex flex-fill flex-column project-layout">
      <ProjectContext.Provider value={projectContext}>
        <ProjectHeader project={project} />
        <div className="d-flex flex-fill">
          {}
          <ProjectSidebar />
          <Outlet context={projectContext} />
        </div>
      </ProjectContext.Provider>
    </div>
  );
};

export default ProjectPage;


