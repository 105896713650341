import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { BaseAPI } from "../../providers/base-api";
import { useNavigate } from "react-router-dom";
import "./score-card.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import "bootstrap/js/src/collapse.js";
import { ProjectContextType, ProjectContext } from "../project/project";
import { PCA, ResponseWithData, TaxonomyItem } from "../../models/project";
import { onlyUnique } from "../../utils/common-utils";
import { GoPrimitiveDot } from "react-icons/go";

export default function ScoreCard() {

  const projectContext: ProjectContextType = useContext(ProjectContext)
  const projectId = projectContext.project.id
  const [pcaSummaryData, setPcaSummaryData] = useState<PCA>();


  const getColorByScore = (metric: number, opacity?: number) => {
    if (typeof metric === 'undefined' || Number.isNaN(metric)) {
      return '#FFFFFF';

    } else {
      const colorTable = [
        { scoreUpto: 24, color: "#E21C3D", colorRGB: 'rgba(226,28,61, :opacity)' },
        { scoreUpto: 50, color: "#FE8318", colorRGB: 'rgba(254,131,24, :opacity)' },
        { scoreUpto: 75, color: "#F9C642", colorRGB: 'rgba(249,198,66, :opacity)' },
        { scoreUpto: 100, color: "#48A463", colorRGB: 'rgba(72,164,99, :opacity)' },
      ]
      const colorItem = colorTable.find(colorItem => colorItem.scoreUpto >= Math.min(metric, 100))
      const color = opacity > 0 ? colorItem.colorRGB.replace(':opacity', `${opacity}`) : colorItem.color
      return color;
    }
  }

  const renderTooltip = (props, dataItem: TaxonomyItem) => {
    const uniqueFeedback: string[] = dataItem.feedbacks.filter(onlyUnique)
    return (
      <Tooltip id="button-tooltip" key={`oltip-${props?.key}`} {...props}>
        {uniqueFeedback.map((feedback, feedbackIndex: number) => {
          return (
            <>
              <p key={`${props?.key}-fb-text-${feedbackIndex}`} className="score-tooltip-text">
                <span key={`${props?.key}-fb-seq-${feedbackIndex}`}>
                  <GoPrimitiveDot key={`${props?.key}-fb-dot-${feedbackIndex}`}/>
                  </span>{feedback}
              </p>
            </>
          )
        })}
      </Tooltip>
    )
  }

  const getProjectPCADetailFn = useCallback(async () => {

    try {
     
      await BaseAPI.getProjectPCASummary(projectId || 1, (result: ResponseWithData<PCA>) => {
        if (result.status === 200) {
          // console.log(result.data);
          const pcaDetail = result.data;
          if (pcaDetail.pcataxonomy) {
            pcaDetail.score = 0;
            pcaDetail.maxScore = 0;
            pcaDetail.pcataxonomy.forEach(pComp => {
              pComp.questionCount = 0;
              pComp.answerCount = 0;
              pComp.score = 0;
              pComp.maxScore = 0;
              pComp.children.forEach(criteria => {
                if (criteria.children) {
                  criteria.questionCount = 0;
                  criteria.answerCount = 0;
                  criteria.score = 0;
                  criteria.maxScore = 0;
                  criteria.feedbacks = [];
                  criteria.children.forEach(subCriteria => {
                    if (subCriteria.questionItems) {
                      criteria.questionCount += subCriteria.questionItems.length;
                      subCriteria.questionItems.forEach(question => {
                        let score = 0, maxScore = 0;
                        question.questionWeight = Math.max(...question.choices.map(choice => choice.weight));
                        maxScore = question.questionWeight
                        if (question.projectAnswer) {
                          criteria.answerCount += 1
                          const choice = question.projectAnswer.answerChoice;
                          if (choice) {
                            choice.isNotApplicable = choice.isNotApplicable || choice.choiceText.toLowerCase() === 'not applicable'
                            if (choice.isNotApplicable) {
                              maxScore = 0
                            }
                            score = choice.weight;
                            if (choice.feedback) {
                              criteria.feedbacks.push(choice.feedback)
                            }
                          }
                        }
                        question.score = score;
                        question.maxScore = maxScore;
                        // const scorePct = Math.round(question.maxScore === 0 ? 0 : (question.score / question.maxScore ) * 100)
                        criteria.score += score;
                        criteria.maxScore += maxScore;
                      })
                    }

                  })
                  // criteria.questionCount = criteria.children.reduce(getChildQuestionCount, 0)
                  pComp.questionCount += criteria.questionCount;
                  pComp.answerCount += criteria.answerCount
                  pComp.score += criteria.score;
                  pComp.maxScore += criteria.maxScore;
                }
              })
              pcaDetail.score += pComp.score;
              if(pComp.maxScore) {
                pcaDetail.maxScore = pcaDetail.maxScore || 0
                pcaDetail.maxScore += pComp.maxScore;
              }
            });

          }

          // console.log('scorecard', pcaDetail)

          // projectContext.projectPCA = result.data
          setPcaSummaryData(pcaDetail)

        } else {
          console.log(result.message);
          //   setPcaSummaryData(defaultData)
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [projectId])

  const navigate = useNavigate();

  useEffect(() => {
    getProjectPCADetailFn();
  }, [getProjectPCADetailFn])


  if (!pcaSummaryData) {
    return null;
  }
  const overallScore = pcaSummaryData.maxScore === 0 ? undefined : Math.round(pcaSummaryData.score / pcaSummaryData.maxScore * 100);
  const overallScoreColor = getColorByScore(overallScore, 0.5)
  const overallScoreText = overallScore === undefined ? 'NA' : `${overallScore}%`;

  return (
    <>
      <div className=" d-flex flex-fill flex-column" id="scorecard-table-container">
        <div className="d-flex flex-fill">

          <main role="main" className="layout-main flex-fill">
            <Container  >
              <div className="main">


                <Row >
                  <Col>

                    <Row >

                      <Col className="table-space">

                        <div>
                          <Row>
                            <Col>
                              <h1 className="score-card-title-projects">Project Readiness Breakdown</h1>
                            </Col>
                            <Col>
                              <h1 className="score-card-tot">Total&nbsp;&nbsp;<span className="badge " style={{ "backgroundColor": overallScoreColor }}>{overallScoreText}</span></h1>
                            </Col>
                          </Row>

                        </div>
                        <Table className=" accordion score-table space-align" >
                          <thead className="score-card-thead" >
                            <tr>
                              <th className="score-card-th-pc" >Project Component</th>
                              <th className="th-border " >Total</th>
                              <th className="score-card-th-pc" >Criteria</th>
                              <th className="sc-tot1">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pcaSummaryData.pcataxonomy.map((pComponent, componentIndex: number) => {
                              const criteriaCount = pComponent.children.length;


                              // const totalScore = Math.round(Math.random() * 100);
                              const totalScorePct = pComponent.maxScore === 0 ? undefined : Math.round((pComponent.score / pComponent.maxScore) * 100);
                              const totalColor = getColorByScore(totalScorePct, 0.5);
                              const oddEvenClass = componentIndex % 2 === 0 ? 'row-odd' : 'row-even';


                              return pComponent.children.map((criteriaItem, criteriaIndex) => {

                                // const score = Math.round(Math.random() * 100);
                                const score = criteriaItem.maxScore === 0 ? undefined : Math.round((criteriaItem.score / criteriaItem.maxScore) * 100);
                                const color = getColorByScore(score, 0.5)

                                return (

                                  <tr key={criteriaIndex} className={`${criteriaCount === (criteriaIndex + 1) ? 'table-row-border' : 'table-row-no-border'} ${oddEvenClass}`}>
                                    {criteriaIndex === 0 &&

                                      <td className="pcName  space-align" rowSpan={criteriaCount}>
                                        <span className="score-card-score">{pComponent.score}/{pComponent.maxScore}</span>
                                        <div className="score-comp-seq">{componentIndex + 1}</div>
                                        <div className="score-comp-text sc-pc-align">{pComponent.name}</div>
                                      </td>

                                    }

                                    {criteriaIndex === 0 &&
                                      <td className="score-center " rowSpan={criteriaCount} style={{ borderBottom: '1px solid #dee2e6' }}>
                                        <div className="badge-component" style={{ "backgroundColor": totalColor }}>
                                          <span className="badge-component-score">{typeof totalScorePct === 'undefined' ? 'NA' : totalScorePct}</span>
                                          {totalScorePct >= 0 &&
                                            <span className="badge-component-pct">%</span>
                                          }
                                        </div>
                                      </td>
                                    }

                                    <td className="criteria-items criteria-items1" >

                                      <span className="criteria-item-text">{criteriaItem.name}</span>


                                      <div className="criteria-score-info" >

                                        {score !== 100 && criteriaItem.feedbacks && criteriaItem.feedbacks.length > 0 &&

                                          <OverlayTrigger
                                            placement="left"
                                            key={`olt-${criteriaIndex}`}
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => renderTooltip(props, criteriaItem)}
                                          >
                                            <span key={`olts-${criteriaIndex}`}>
                                              < AiOutlineInfoCircle className="score-card-icon sc-tot-score" key={`olti-${criteriaIndex}`} />
                                            </span>
                                          </OverlayTrigger>
                                        }
                                        <span className="score-card-score sc-tot-score">{criteriaItem.score}/{criteriaItem.maxScore}</span>

                                      </div>

                                    </td>

                                    <td className="score-center" >
                                      <div className="badge-criteria" style={{ "backgroundColor": color }}>
                                        <span className="criteria-score" >{typeof score === 'undefined' ? 'NA' : score}</span>
                                        {score >= 0 &&
                                          <span className="badge-component-pct">%</span>
                                        }
                                      </div>

                                    </td>
                                  </tr>
                                )
                              })
                            })}

                          </tbody>
                        </Table>

                      </Col>

                    </Row>

                  </Col>
                </Row>
              </div>
            </Container>
          </main>
        </div>
      </div>

    </>
  );
}

