import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Outlet, To, useNavigate } from "react-router-dom";
import ButtonPrimary from "../../components/buttons/buttons";
import DataTable from "../../components/data-table/data-table";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import { BsThreeDotsVertical } from "react-icons/bs";
import "./project-listing.css";

export default function ProjectListingPage() {
  const navigate = useNavigate();
  const handleClick = function (linkTo: To) {
    navigate(linkTo);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDeleteData(null);
    setDeleteStatus(false);
    getProjects();
  };
  const handleShow = (value) => {
    setShow(true);
    setDeleteData(value);
  };
  // const params = useParams();
  // const id = params && params.id ? params.id : undefined;
  const [projectData, setProjectData] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);

  const [columnData] = useState([
    {
      header: "",
      accessor: "button",
      style: { verticalAlign: "middle" },
      Cell: (d: any) => iconFunction(d),
    },
    {
      header: "Project Name",
      style: {
        verticalAlign: "middle",
        fontSize: "14px",
        fontWeight: "700",
        paddingLeft: "13px",
        width: "340px",
      },
      accessor: "button",
      Cell: (d: any) => editLinkData(d),
    },
    {
      header: "Type of Project",
      style: {
        verticalAlign: "middle",
        fontWeight: "300",
        fontSize: "12px",
        width: "9rem",
        paddingLeft: "33px",
      },
      accessor: "typeOfProject.name",
    },
    {
      header: "Project Category",
      style: {
        verticalAlign: "middle",
        fontWeight: "300",
        fontSize: "12px",
        paddingLeft: "23px",
      },
      accessor: "projectCategory.name",
    },
    {
      header: "Location",
      style: {
        verticalAlign: "middle",
        fontWeight: "300",
        fontSize: "12px",
        paddingLeft: "18px",
        width: "110px",
      },
      accessor: "location.name",
    },
    {
      header: "Modified",
      accessor: "button",
      style: {
        verticalAlign: "middle",
        fontWeight: "300",
        fontSize: "12px",
        paddingLeft: "46px",
      },
      Cell: (d: any) => <>{dayjs(d?.updatedAt).format("MM/DD/YY")}</>,
    },
    {
      header: "",
      accessor: "button",
      style: { paddingTop: "10px", fontWeight: "300", fontSize: "12px" },
      Cell: (d: any) => buttonFunction(d),
    },
  ]);
  const buttonFunction = (d: any) => {
    return (
      <div className="project-listing-link-space">
        <span>
          <a
            className="project-listing-link-dec"
            href={`/projects/${d.id}/programs`}
          >
            Funding Eligilibility
          </a>
        </span>
        <span>
          <a
            className="project-listing-link-dec"
            href={`/projects/${d.id}/summary`}
          >
            Readiness
          </a>
        </span>
      </div>
    );
  };
  const iconFunction = (d: any) => {
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
            <BsThreeDotsVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/projects/${d.id}`}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleShow(d)}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const editLinkData = (d: any) => {
    return (
      <a
        className="project-listing-project-name-link"
        href={`/projects/${d.id}`}
      >
        {d.title}
      </a>
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  const deleteProject = async () => {
    setIsLoading(true);
    try {
      await BaseAPI.deleteProject(deleteData?.id, (result: any) => {
        setDeleteStatus(true);
        if (result.status === 200) {
          setIsLoading(false);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getProjects = async () => {
    setIsLoading(true);
    try {
      await BaseAPI.getProjects((result: any) => {
        setIsLoading(false);
        if (result.status === 200) {
          setProjectData(result.data);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="project-listing-div-height">
      <Helmet>
        <title>Project Listing</title>
      </Helmet>
      {
        <>
          <Container fluid className="ctn-top-space">
            <Row>
              <Col xs={1}></Col>
              <Col xs={5} className="project-listing-tit-align">
                <TitleCard
                  title="My Projects"
                  description="Better understand which federal funding your project is eligible to receive and whether it's ready for an application ."
                />
              </Col>
              <Col xs={5}>
                <ButtonPrimary
                  className="project-listing-btn-align"
                  onClick={() => handleClick("/projects/new")}
                >
                  NEW PROJECT +
                </ButtonPrimary>
              </Col>
              <Col xs={1}></Col>
            </Row>
          </Container>
          {projectData.length === 0 && !isLoading ? (
            <Container>
              <Row>
                <Col xs={2}></Col>
                <Col xs={8} className="project-listing-tbl-align">
                  <p className="project-listing-no-data-found">
                    You haven't created any projects. Click on the{" "}
                    <b>"New Project"</b> button to get started
                  </p>
                </Col>
                <Col xs={2}></Col>
              </Row>
            </Container>
          ) : (
            ""
          )}
          {isLoading ? (
            <Spinner
              className="project-listing-loader-spinner-moon"
              animation="border"
              variant="info"
            />
          ) : null}
          {projectData.length > 0 && (
            <Container>
              <Row>
                <Col xs={2}></Col>
                <Col xs={8} className="project-listing-tbl-align">
                  <DataTable columnData={columnData} dataResult={projectData} />
                </Col>
                <Col xs={2}></Col>
              </Row>
            </Container>
          )}
        </>
      }
      <Outlet />
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
          className="delete-confirmation-dialog"
        >
          <Modal.Header>
            {!deleteStatus ? <Modal.Title className="delete-confirmation-title">Are you sure you want to delete this project?</Modal.Title> :
              <Modal.Title className="delete-confirmation-success">Success ! </Modal.Title>}
          </Modal.Header>
          {isLoading ? (
            <Spinner
              className="project-listing-delete-loader-spinner-moon"
              animation="border"
              variant="info"
            />
          ) : null}
          <Modal.Body className="delete-confirmation-body">
            {!deleteStatus
              ? `You are about to delete the project "${deleteData?.title}" and all PCA answers submitted. Would you like to proceed?`
              : "You have successfully deleted your project"}
          </Modal.Body>
          <br></br>
          <Modal.Footer className="delete-confirmation-footer">
            {!deleteStatus ?
              <Button className="delete-cancel" onClick={handleClose}>
                Cancel
              </Button>
              : <Button onClick={handleClose}>
                Close
              </Button>}
            {!deleteStatus ?
              <Button className="delete-button" onClick={deleteProject}>
                Delete
              </Button>
              : null}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
