import dayjs from "dayjs";  
import { Project } from "../../models/project";
import "./project-header.css";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAuth } from "../../hooks/useAuth";

export default function ProjectHeader(props: { project: Project }) {
  const project = props.project;
  dayjs.extend(relativeTime);
  const {user} = useAuth();
  const createDateFormatted = dayjs(project.createdAt).fromNow();
  const updatedDateFormatted = dayjs(project.updatedAt).fromNow();
  const isValidProject = !Number.isNaN(project?.id) && project.id > 0 && user;

  return (
    <div className="top-bar">
      <div className="create">
        <ul>
          <li>Created {createDateFormatted}</li>
          <li>Modified {updatedDateFormatted}</li>
        </ul>
      </div>

      <div className="container-fluid">
        <div className="d-flex flex-fill"></div>
        <div className="row">
          <div className="col-12 name-align">
            <div className="title-name">
              <p>{project.title}</p>
            </div>
            <div className="cards">
              {isValidProject && 
              <ul>
                <li>
                  {/* <Button id="btn-programs" className="btn-header-link" style={{backgroundColor: "#003e7a", borderColor: "#003e7a"}} href={`/projects/${project.id||'new'}/programs`} disabled={!isValidProject}>
                  Program Eligibility
                  </Button> */}
                  <a className="header-link" href={`/projects/${project.id||'new'}/programs`}>Funding Eligibility</a>
                  </li>
                <li>
                {/* <Button id="btn-programs" className="btn-header-link" style={{backgroundColor: "#003e7a", borderColor: "#003e7a"}} href={`/projects/${project.id||'new'}/summary`} disabled={!isValidProject}>
                Project Readiness
                  </Button> */}

                  <a className="header-link" href={`/projects/${project.id||'new'}/summary`}>Readiness</a>
                  </li>
              </ul>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
